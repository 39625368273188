import http from './http'
import { ScanT } from 'store/scan'

export async function getScansRequest() {
    return (await http.get(`/dashboard/scans`)).data
}

export async function delScansRequest(scans: ScanT[]) {
    const data: { [key: string]: string[] } = {}

    for (const scan of scans) {
        if (!data[scan.domain]) {
            data[scan.domain] = [] // Initialize the array if it doesn't exist
        }
        data[scan.domain].push(scan.uuid) // Push the domain into the array
    }

    return (await http.delete(`/dashboard/scans`, { data })).data
}

export async function getScanDetailsRequest(domain: string, id: string) {
    return (await http.get(`/dashboard/scan-detail?scan_id=${id}&domain=${domain}`)).data
}

export async function postScanURLRequest(scans: ScanT[]) {
    const data = scans.map((scan) => {
        return {
            scan_id: scan.uuid,
            domain: scan.domain,
        }
    })

    return (await http.post(`/dashboard/scans-download`, data)).data
}
