import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { RootState } from 'store'

interface LayoutState {
    pageLoader: boolean
}

export const initialState: LayoutState = {
    pageLoader: false,
}

export const layoutSlice = createSlice({
    name: 'layout',
    initialState,
    reducers: {
        setLayoutPageLoader: (state, { payload }: PayloadAction<boolean>) => {
            state.pageLoader = payload
        },
    },
})

export const { setLayoutPageLoader } = layoutSlice.actions

export const layoutSelector = (state: RootState) => state.layout

export default layoutSlice.reducer
