/*
 * Author: dizhong zhu
 * Date: 20/03/2023
 */

import { Col, Container, Row } from 'react-bootstrap'
import React, { Component } from 'react'
import { ScanDetailModal } from './ScanDetailModal'
import FileSaver from 'file-saver'
import JSZip from 'jszip'
import { ZipProgress } from '../comp/ZipProgress'
import Papa from 'papaparse'
import { ScanDispTable } from './ScanDispTable'
import { ScanT, ScanURLT } from 'store/scan'

import { TimeFilters } from '../../../widgets/UtilWidgets'

interface props {
    scans: ScanT[]
    getScanURL: (scan: ScanT[]) => Promise<any>
    deleteScans: (scan: ScanT[]) => Promise<void>
}

interface state {
    startDate: any
    endDate: any
    showDetail: boolean
    selectScanIdx: number
    isDownloading: boolean
    zipProgress: number
    filterScan: ScanT[]
    isCancelled: boolean
}

export class ScanDispEntry extends Component<props, state> {
    private isCancelled = false

    constructor(props: props) {
        super(props)
        this.state = {
            startDate: undefined,
            endDate: undefined,
            showDetail: false,
            selectScanIdx: -1,
            isDownloading: false,
            zipProgress: 0,
            filterScan: props.scans,
            isCancelled: false,
        }
    }

    componentDidUpdate(prevProps: props, prevState: state) {
        if (prevProps.scans !== this.props.scans || prevState.startDate !== this.state.startDate || prevState.endDate !== this.state.endDate) {
            this.updateFilteredScans()
        }
    }

    updateFilteredScans = () => {
        const { scans } = this.props
        const { startDate, endDate } = this.state
        let filteredScans = scans

        if (startDate) {
            filteredScans = filteredScans.filter((scan) => new Date(scan.scan_at) >= startDate)
        }

        if (endDate) {
            filteredScans = filteredScans.filter((scan) => new Date(scan.scan_at) <= endDate)
        }

        this.setState({ filterScan: filteredScans })
    }

    handleStartDateChange = (date: any) => {
        this.setState({ startDate: date })
    }

    handleEndDateChange = (date: any) => {
        this.setState({ endDate: date })
    }

    handleScanClick = (uuid: string) => {
        const { filterScan } = this.state
        const scanIndex = filterScan.findIndex((scan) => scan.uuid === uuid)
        this.setState({ selectScanIdx: scanIndex, showDetail: true })
    }

    convertJsonToCsv = (jsonData: any) => {
        const csvData = []
        const header = Object.keys(jsonData)
        csvData.push(header)

        const data = Object.values(jsonData)
        csvData.push(data)

        const csv = Papa.unparse(csvData)
        return csv
    }

    handleDownload = async (isSelectedDownloads = false, selectedScans: ScanT[] = []) => {
        this.isCancelled = false
        this.setState({ isDownloading: true, zipProgress: 0 })

        const { getScanURL } = this.props

        const fileURLs: ScanURLT[] = isSelectedDownloads ? await getScanURL(selectedScans) : await getScanURL(this.state.filterScan)

        const zip = new JSZip()

        for (const [index, url] of fileURLs.entries()) {
            if (this.isCancelled) {
                console.log('Download cancelled')
                return
            }

            const folderName = url.uuid
            const folder = zip.folder(folderName)

            const fetchFile = async (url: string) => {
                return fetch(url).then((response) => response.blob())
            }

            await Promise.all(
                Object.entries(url.files).map(async ([, value]) => {
                    return fetchFile(value?.url).then((blob) => {
                        if (value?.filename === 'measurements.json') {
                            const reader = new FileReader()
                            reader.onload = () => {
                                const csvData = this.convertJsonToCsv(JSON.parse(reader.result as string))
                                folder?.file('measurements.csv', csvData)
                            }
                            reader.readAsText(blob)
                        }

                        return folder?.file(value?.filename, blob)
                    })
                })
            )

            this.setState({ zipProgress: Math.round(((index + 1) / fileURLs.length) * 100) })
        }

        const zipBlob = await zip.generateAsync({ type: 'blob' })
        FileSaver.saveAs(zipBlob, 'data.zip')

        this.setState({ isDownloading: false })
    }

    handleDownloadCancel = () => {
        this.isCancelled = true
        this.setState({ isDownloading: false, zipProgress: 0 })
    }

    prevScan = () => {
        this.setState((prevState) => ({
            selectScanIdx: Math.max(prevState.selectScanIdx - 1, 0),
        }))
    }

    nextScan = () => {
        this.setState((prevState) => ({
            selectScanIdx: Math.min(prevState.selectScanIdx + 1, prevState.filterScan.length - 1),
        }))
    }

    render() {
        const { startDate, endDate, showDetail, selectScanIdx, isDownloading, zipProgress, filterScan } = this.state

        return (
            <React.Fragment>
                <Container fluid className="h-100 d-flex flex-column">
                    <Row>
                        <Col md={8}>
                            <TimeFilters startDate={startDate} OnStartDateChange={this.handleStartDateChange} endDate={endDate} OnEndDateChange={this.handleEndDateChange} />
                        </Col>
                    </Row>
                    <Row className="flex-grow-1 overflow-hidden">
                        <Col className="h-100 overflow-auto" style={{ paddingRight: 0, paddingLeft: 0 }}>
                            <ScanDispTable
                                scans={filterScan}
                                OnScanClick={this.handleScanClick}
                                isDownloading={isDownloading}
                                handleDownload={this.handleDownload}
                                handleDelete={this.props.deleteScans}
                            />
                        </Col>
                    </Row>

                    <ScanDetailModal show={showDetail} OnHide={() => this.setState({ showDetail: false })} OnPrev={this.prevScan} OnNext={this.nextScan} scan={filterScan[selectScanIdx]} />

                    <ZipProgress bProgress={isDownloading} progress={zipProgress} onCancel={this.handleDownloadCancel} />
                </Container>
            </React.Fragment>
        )
    }
}
