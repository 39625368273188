/*
 * Author: dizhong zhu
 * Date: 10/10/2024
 */

import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { delGarmentTaggingRequest, listGarmentTaggingRequest } from '../services/garment-tagging'

/************************************************************************************************
 *
 *                                Types and Interfaces
 *
 ************************************************************************************************/

export interface GarmentTaggingT {
    uuid: string
    domain: string
    complete: boolean
    input_img: string
    input_attribute: any
    output_measure_img: string
    output_tagging: any
    output_measure: any
    output_description: any
    created_at: any
}

interface GarmentTaggingResponse {
    data: any
    error: any[]
    message: string
    status: boolean
}

interface GarmentTaggingState {
    garmentTaggingItems: GarmentTaggingT[]
    isGarmentTaggingLoading: boolean
    errors: any[]
}

export const initialState: GarmentTaggingState = {
    garmentTaggingItems: [],
    isGarmentTaggingLoading: false,
    errors: [],
}

/************************************************************************************************
 *
 *                                          Functions
 *
 ************************************************************************************************/
export const listGarmentTagging = createAsyncThunk<GarmentTaggingT[]>('garment-tagging/list', async (_, { dispatch }) => {
    dispatch(setGarmentTaggingIsLoading(true))
    return listGarmentTaggingRequest()
        .then((res: GarmentTaggingResponse) => {
            let itemList: GarmentTaggingT[] = []
            const garmentTaggingItems = res.data
            let index = 0
            for (const key in garmentTaggingItems) {
                const item_with_domains = garmentTaggingItems[key].map((item: GarmentTaggingT) => {
                    return { ...item, ID: index++, domain: key }
                })
                itemList = [...itemList, ...item_with_domains]
            }
            dispatch(setGarmentTaggingItems(itemList))
            return itemList
        })
        .catch((err: any) => {
            dispatch(setError([err.response.data.message]))
        })
        .finally(() => dispatch(setGarmentTaggingIsLoading(false))) as Promise<GarmentTaggingT[]>
})

export const delGarmentTagging = createAsyncThunk<void, GarmentTaggingT[]>('garment-tagging/delete', async (item: GarmentTaggingT[], { dispatch }) => {
    return delGarmentTaggingRequest(item)
        .then((res: GarmentTaggingResponse) => {})
        .catch((err: any) => {
            dispatch(setError([err.response.data.message]))
        })
})

export const garmentTaggingSlice = createSlice({
    name: 'garment-tagging',
    initialState,
    reducers: {
        setGarmentTaggingItems: (state, { payload }: PayloadAction<GarmentTaggingT[]>) => {
            state.garmentTaggingItems = payload
        },
        setError: (state, { payload }: PayloadAction<any>) => {
            state.errors = payload
        },
        setGarmentTaggingIsLoading: (state, { payload }: PayloadAction<boolean>) => {
            state.isGarmentTaggingLoading = payload
        },
    },
})

export const { setGarmentTaggingItems, setGarmentTaggingIsLoading, setError } = garmentTaggingSlice.actions

export default garmentTaggingSlice.reducer
