/*
 * Author: dizhong zhu
 * Date: 05/04/2023
 */

import React, { useState } from 'react'
import { NavLink } from 'react-router-dom'
import { Button, Card, Container, Form } from 'react-bootstrap'
import { DisplayAlert, DisplaySuccess } from 'widgets/DisplayAlert'
import { Api_ForgetPassword } from 'apis/accounts'
import { Logo } from 'assets/png'
import { FormInput } from 'widgets/FormInput'
import { LOGIN_ROUTE } from 'routes/path'

export default function ForgotPassword() {
    const [email, setEmail] = useState('')
    // const [message, setMessage] = useState("");

    const handleEmailChange = ({ currentTarget: input }: any) => {
        setEmail(input.value)
    }

    const handleSend = async (event: any) => {
        event.preventDefault()
        // Add your logic to send the reset link to the email here.
        try {
            await Api_ForgetPassword(email)
            DisplaySuccess('Email sent, please check your email and follow the instruction to reset your password.')
        } catch (error: any) {
            DisplayAlert(error.message)
        }
    }

    return (
        <React.Fragment>
            <Container className="d-flex align-items-center justify-content-center" style={{ height: '100vh' }}>
                <Card className="m-3" style={{ minWidth: '800px' }}>
                    <Card.Header className="text-center">
                        <img src={Logo} alt="Logo" width={'50%'} />
                    </Card.Header>
                    <Card.Body>
                        <Form onSubmit={handleSend}>
                            <FormInput name={'email'} label={'Your email to receive the reset link'} type={'email'} placeholder={'Enter email'} value={email} onChange={handleEmailChange} />
                            <div className="text-center">
                                <Button className="bg-blue-600" type="submit">
                                    Send
                                </Button>
                            </div>
                        </Form>
                        <p className="text-center">
                            <Button variant="link">
                                <NavLink className={'nav-item nav-link'} to={LOGIN_ROUTE}>
                                    Back to sign in
                                </NavLink>
                            </Button>
                        </p>
                    </Card.Body>
                </Card>
            </Container>
        </React.Fragment>
    )
}
