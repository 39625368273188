/*
 * Author: dizhong zhu
 * Date: 16/09/2024
 */

import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { delCustomerRequest, getCustomerListRequest } from 'services/customers'

/************************************************************************************************
 *
 *                                Types and Interfaces
 *
 ************************************************************************************************/
export interface CustomerT {
    customer_id: string
    domain: string
    email: string
    first_name: string
    last_name: string
    phone: string
}

interface CustomerResponse {
    data: any
    error: any[]
    message: string
    status: boolean
}

interface CustomerState {
    customerItems: CustomerT[]
    isLoading: boolean
    errors: any[]
}

export const initialState: CustomerState = {
    customerItems: [],
    isLoading: true,
    errors: [],
}

/************************************************************************************************
 *
 *                                          Functions
 *
 ************************************************************************************************/

export const getCustomerList = createAsyncThunk<CustomerT[]>('customer/list', async (_, { dispatch }) => {
    dispatch(setIsLoading(true))
    return getCustomerListRequest()
        .then((res: CustomerResponse) => {
            let itemList: CustomerT[] = []
            const quickSizeItems = res.data
            let index = 0
            for (const key in quickSizeItems) {
                const item_with_domains = quickSizeItems[key].map((item: CustomerT) => {
                    return { ...item, ID: index++, domain: key }
                })
                itemList = [...itemList, ...item_with_domains]
            }
            dispatch(setCustomerItems(itemList))
            return itemList
        })
        .catch((err: any) => {
            dispatch(setError([err.response.data.message]))
        })
        .finally(() => dispatch(setIsLoading(false))) as Promise<CustomerT[]>
})

export const delCustomers = createAsyncThunk<void, CustomerT[]>('quick-size/delete', async (item: CustomerT[], { dispatch }) => {
    return delCustomerRequest(item)
        .then((res: CustomerResponse) => {})
        .catch((err: any) => {
            dispatch(setError([err.response.data.message]))
        })
})

export const customerSlice = createSlice({
    name: 'customer',
    initialState,
    reducers: {
        setCustomerItems: (state, { payload }: PayloadAction<CustomerT[]>) => {
            state.customerItems = payload
        },
        setError: (state, { payload }: PayloadAction<any>) => {
            state.errors = payload
        },
        setIsLoading: (state, { payload }: PayloadAction<boolean>) => {
            state.isLoading = payload
        },
    },
})

export const { setCustomerItems, setIsLoading, setError } = customerSlice.actions

export default customerSlice.reducer
