/*
 * Author: dizhong zhu
 * Date: 03/04/2023
 */

// Error types
import { DisplayAlert } from '../widgets/DisplayAlert'

// Error types
export const ERROR_AUTHENTICATION_FAILED = 'AUTHENTICATION_FAILED'
export const ERROR_UKNOWN = 'UKNOWN'
export const ERROR_USER_NOT_FOUND = 'USER_NOT_FOUND'
export const ERROR_USER_EXISTS = 'USER_EXISTS'

// Error handlers

class APIError extends Error {
    constructor(type, message) {
        super(message)
        this.type = type
    }
}

function APIThrowError(error) {
    if (error.response && error.response.status === 401) {
        throw new APIError(ERROR_AUTHENTICATION_FAILED, error.response?.data?.message)
    } else {
        throw new APIError(ERROR_UKNOWN, error.response?.data?.message + '\nreason: ' + error.response?.data?.error[0])
    }
}

function APIErrorHandler() {
    const handleError = (error) => {
        if (error) {
            if (error.type === ERROR_AUTHENTICATION_FAILED) {
                DisplayAlert(ERROR_AUTHENTICATION_FAILED + ': ' + error.message)
            } else {
                DisplayAlert(error.message)
            }
        }
    }

    return handleError
}

export { APIError, APIThrowError, APIErrorHandler }
