/*
 * Author: dizhong zhu
 * Date: 16/09/2024
 */

import http from './http'
import { CustomerT } from 'store/customers'

export async function getCustomerListRequest() {
    return (await http.get(`/dashboard/customer/list`)).data
}

export async function delCustomerRequest(items: CustomerT[]) {
    const data: { [key: string]: string[] } = {}

    for (const item of items) {
        if (!data[item.domain]) {
            data[item.domain] = [] // Initialize the array if it doesn't exist
        }
        data[item.domain].push(item.customer_id) // Push the domain into the array
    }

    return (await http.delete(`/dashboard/customer`, { data })).data
}
