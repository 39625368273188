import http from './http'

export async function getTransactionStatsRequest(domain: string, startDate: Date, endDate: Date) {
    return (
        await http.get(`/dashboard/analytic/transaction-stat`, {
            headers: { DOMAIN: domain },
            params: {
                start_date: startDate.toISOString(),
                end_date: endDate.toISOString(),
            },
        })
    ).data
}

export async function getVisitorStatsRequest(domain: string, startDate: Date, endDate: Date) {
    return (
        await http.get(`/dashboard/analytic/page-view?granularity=minutes`, {
            headers: { DOMAIN: domain },
            params: {
                start_date: startDate.toISOString(),
                end_date: endDate.toISOString(),
            },
        })
    ).data
}

export async function getTransactionRequest(domain: string, startDate: Date, endDate: Date) {
    return (
        await http.get(`/dashboard/analytic/transaction`, {
            headers: { DOMAIN: domain },
            params: {
                start_date: startDate.toISOString(),
                end_date: endDate.toISOString(),
            },
        })
    ).data
}
