/*
 * Author: dizhong zhu
 * Date: 11/09/2024
 */

import http from './http'

export async function getServicesVersionRequest() {
    return (await http.get(`/version`)).data
}
