/*
 * Author: dizhong zhu
 * Date: 10/10/2024
 */

import http from './http'
import { GarmentTaggingT } from '../store/garment-tagging'

export async function listGarmentTaggingRequest() {
    return (await http.get(`/dashboard/garment-tagging/list`)).data
}

export async function delGarmentTaggingRequest(items: GarmentTaggingT[]) {
    const data: { [key: string]: string[] } = {}

    for (const item of items) {
        if (!data[item.domain]) {
            data[item.domain] = [] // Initialize the array if it doesn't exist
        }
        data[item.domain].push(item.uuid) // Push the domain into the array
    }

    return (await http.delete(`/dashboard/garment-tagging`, { data })).data
}
