/*
 * Author: dizhong zhu
 * Date: 27/04/2023
 */

// TenSettingSizeChart.js

import React from 'react'
import { Card, Container } from 'react-bootstrap'
import { Divider } from 'widgets/UtilWidgets'
import { BoardClothItems } from './BoardClothItems'
import { BoardSizeChart } from './BoardSizeChart'

interface props {
    domain: string
}

const TenSettingSizeChart: React.FC<props> = ({ domain }) => {
    return (
        <Container fluid>
            <Card className="setting-boarder mb-4">
                <Card.Body>
                    <h4>Configuration of size chart</h4>
                    <Divider className="my-2" />
                    <BoardSizeChart domain={domain} />
                    {/*<BoardSizeChart_old domain={domain}/>*/}

                    <h4>Configuration of cloth itmes</h4>
                    <Divider className="my-2" />
                    <BoardClothItems domain={domain} />
                </Card.Body>
            </Card>
        </Container>
    )
}

export { TenSettingSizeChart }
