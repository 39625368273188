import React, { Suspense as CoreSuspense } from 'react'
import Loader from 'components/Loader'

interface SuspenseProps {
    children: React.ReactNode
}

const Suspense = (props: SuspenseProps) => {
    return <CoreSuspense fallback={<Loader />}>{props.children}</CoreSuspense>
}

export default Suspense
