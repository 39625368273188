/*
 * Author: dizhong zhu
 * Date: 28/04/2023
 */

import axios from 'axios'
import { APIThrowError } from './apiErros'
import { dataURLtoFileBlob } from '../utils/data_process'

const Api_CreateSizeChart = (domain, token, item) => {
    const config = {
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
            DOMAIN: domain,
        },
    }

    return axios
        .post(`${process.env.REACT_APP_API_ENDPOINT}/dashboard/sizechart`, item, config)
        .then((response) => {
            if (response.data.status === true) {
                return response.data?.data
            }
        })
        .catch((error) => {
            APIThrowError(error)
        })
}

const Api_GetSizeChart = (domain, token) => {
    const config = {
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
            DOMAIN: domain,
        },
    }

    return axios
        .get(`${process.env.REACT_APP_API_ENDPOINT}/dashboard/sizechart`, config)
        .then((response) => {
            if (response.data.status === true) {
                return response.data?.data
            }
        })
        .catch((error) => {
            APIThrowError(error)
        })
}

const Api_UpdateSizeChart = (domain, token, item) => {
    const config = {
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
            DOMAIN: domain,
        },
    }

    return axios
        .patch(`${process.env.REACT_APP_API_ENDPOINT}/dashboard/sizechart`, item, config)
        .then((response) => {
            if (response.data.status === true) {
                return response.data?.data
            }
        })
        .catch((error) => {
            APIThrowError(error)
        })
}

const Api_DeleteSizeChart = (domain, token, item) => {
    const config = {
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
            DOMAIN: domain,
        },
        data: {
            names: [item.name],
        },
    }

    return axios
        .delete(`${process.env.REACT_APP_API_ENDPOINT}/dashboard/sizechart`, config)
        .then((response) => {
            if (response.data.status === true) {
                return response.data?.data
            }
        })
        .catch((error) => {
            APIThrowError(error)
        })
}

const Api_GetClothItems = (domain, token) => {
    const config = {
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
            DOMAIN: domain,
        },
    }

    return axios
        .get(`${process.env.REACT_APP_API_ENDPOINT}/dashboard/clothitem`, config)
        .then((response) => {
            if (response.data.status === true) {
                return response.data?.data
            }
        })
        .catch((error) => {
            APIThrowError(error)
        })
}

const Api_CreateClothItems = (domain, token, item) => {
    const config = {
        headers: {
            Authorization: `Bearer ${token}`,
            DOMAIN: domain,
        },
    }
    const formData = new FormData()
    formData.append('image', dataURLtoFileBlob(item.image_blob), `${item.name}${item.image_type}`)
    formData.append('name', item.name)

    formData.append('uuid', item.uuid)
    formData.append('gender', item.gender)
    formData.append('size_chart', item.size_chart)
    formData.append('disp_measure', item.disp_measure)

    return axios
        .post(`${process.env.REACT_APP_API_ENDPOINT}/dashboard/clothitem`, formData, config)
        .then((response) => {
            if (response.data.status === true) {
                return response.data?.data
            }
        })
        .catch((error) => {
            APIThrowError(error)
        })
}

const Api_DeleteClothItems = (domain, token, uuids) => {
    const config = {
        headers: {
            Authorization: `Bearer ${token}`,
            DOMAIN: domain,
        },
        data: {
            uuids: uuids,
        },
    }

    return axios
        .delete(`${process.env.REACT_APP_API_ENDPOINT}/dashboard/clothitem`, config)
        .then((response) => {
            if (response.data.status === true) {
                return response.data?.data
            }
        })
        .catch((error) => {
            APIThrowError(error)
        })
}

const Api_UpdateClothItems = (domain, token, item) => {
    const config = {
        headers: {
            Authorization: `Bearer ${token}`,
            DOMAIN: domain,
        },
    }
    const formData = new FormData()
    formData.append('image', dataURLtoFileBlob(item.image_blob), `${item.name}${item.image_type}`)
    formData.append('name', item.name)

    formData.append('uuid', item.uuid)
    formData.append('gender', item.gender)
    formData.append('size_chart', item.size_chart)
    formData.append('disp_measure', item.disp_measure)

    return axios
        .patch(`${process.env.REACT_APP_API_ENDPOINT}/dashboard/clothitem`, formData, config)
        .then((response) => {
            if (response.data.status === true) {
                return response.data?.data
            }
        })
        .catch((error) => {
            APIThrowError(error)
        })
}

export { Api_CreateSizeChart, Api_GetSizeChart, Api_UpdateSizeChart, Api_DeleteSizeChart, Api_GetClothItems, Api_CreateClothItems, Api_DeleteClothItems, Api_UpdateClothItems }
