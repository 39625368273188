/*
 * Author: dizhong zhu
 * Date: 11/09/2024
 */

import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { getServicesVersionRequest } from '../services/servicesVersion'

/************************************************************************************************
 *
 *                                Types and Interfaces
 *
 ************************************************************************************************/
export interface ServiceVersionT {
    engine: string
    quicksizer: string
    pose_detection: string
    segmentation: string
    gsam: string
    preprocessor: string
    reconstruction: string
    garment_tagging: string
}

interface VersionResponse {
    data: any
    error: any[]
    message: string
    status: boolean
}

interface VersionState {
    servicesVersion: ServiceVersionT
    isLoading: boolean
    errors: any[]
}

export const initialState: VersionState = {
    servicesVersion: { engine: '', quicksizer: '', pose_detection: '', segmentation: '', gsam: '', preprocessor: '', reconstruction: '', garment_tagging: '' },
    isLoading: true,
    errors: [],
}

/************************************************************************************************
 *
 *                                          Functions
 *
 ************************************************************************************************/

export const getServicesVersion = createAsyncThunk<ServiceVersionT>('services-version/', async (_, { dispatch }) => {
    dispatch(setIsLoading(true))
    return getServicesVersionRequest()
        .then((res: VersionResponse) => {
            dispatch(setVersions(res.data))
            return res.data
        })
        .catch((err: any) => {
            dispatch(setError([err.response.data.message]))
        })
        .finally(() => dispatch(setIsLoading(false))) as Promise<ServiceVersionT>
})

export const servicesVersionSlice = createSlice({
    name: 'services-version',
    initialState,
    reducers: {
        setVersions: (state, { payload }: PayloadAction<ServiceVersionT>) => {
            state.servicesVersion = payload
        },
        setError: (state, { payload }: PayloadAction<any>) => {
            state.errors = payload
        },
        setIsLoading: (state, { payload }: PayloadAction<boolean>) => {
            state.isLoading = payload
        },
    },
})

export const { setVersions, setIsLoading, setError } = servicesVersionSlice.actions

export default servicesVersionSlice.reducer
