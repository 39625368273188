/*
 * Author: dizhong zhu
 * Date: 16/09/2024
 */

import React, { Component } from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import { CustomerT } from 'store/customers'
import { CustomerDisplayTable } from './CustomerTable'

interface props {
    customerItems: CustomerT[]
    deleteCustomerItems: (item: CustomerT[]) => Promise<void>
}

interface state {
    filterItems: CustomerT[]
}

export class CustomerDisplayEntry extends Component<props, state> {
    constructor(props: props) {
        super(props)
        this.state = {
            filterItems: props.customerItems,
        }
    }

    componentDidUpdate(prevProps: props, prevState: state) {
        if (prevProps.customerItems !== this.props.customerItems) {
            this.updateFilteredScans()
        }
    }

    updateFilteredScans = () => {
        const { customerItems } = this.props
        this.setState({ filterItems: customerItems })
    }

    render() {
        const { filterItems } = this.state
        return (
            <React.Fragment>
                <Container fluid className="h-100 d-flex flex-column">
                    <Row className="flex-grow-1 overflow-hidden">
                        <Col className="h-100 overflow-auto" style={{ paddingRight: 0, paddingLeft: 0 }}>
                            <CustomerDisplayTable customerItems={filterItems} handleDelete={this.props.deleteCustomerItems} />
                        </Col>
                    </Row>
                </Container>
            </React.Fragment>
        )
    }
}
