/*
 * Author: dizhong zhu
 * Date: 16/09/2024
 */

import { useEffect, useState } from 'react'
import { Button, Col, Container, Row } from 'react-bootstrap'
import { TenantSideBar } from 'widgets/TenantSideBar'
import { useAppDispatch, useAppSelector } from 'store'
import PageLoader from 'components/PageLoader'
import { CustomerT, delCustomers, getCustomerList } from 'store/customers'
import { CustomerDisplayEntry } from './DisplayArea/CustomerEntry'

export const CustomersView = () => {
    const dispatch = useAppDispatch()

    const [currentDomain, setCurrentDomain] = useState('')
    const [currentItems, setCurrentItems] = useState<CustomerT[]>([])

    const { tenants } = useAppSelector((state) => state.tenant)
    const { customerItems, isLoading } = useAppSelector((state) => state.customers)

    useEffect(() => {
        dispatch(getCustomerList())
    }, [])

    useEffect(() => {
        const filterItems = filterItemByDomain(currentDomain)
        setCurrentItems(filterItems)
    }, [customerItems, currentDomain])

    const filterItemByDomain = (domain: string) => {
        if (domain === '') {
            return customerItems
        }
        const filteredScans = customerItems.filter((item: any) => item.domain === domain)
        return filteredScans.map((item, index) => ({ ...item, ID: index }))
    }

    const deleteCustomerItems = async (items: CustomerT[]) => {
        await dispatch(delCustomers(items))
        dispatch(getCustomerList())
    }

    const handleTenantClick = async (domain: string) => {
        setCurrentDomain(domain)
    }

    return (
        <PageLoader open={isLoading}>
            <Container fluid className="h-100">
                <Row className="h-100">
                    {/* The col show tenants and clickable*/}
                    <Col md={2} className="d-flex flex-column h-100 page-height">
                        <TenantSideBar tenants={tenants} currentDomain={currentDomain} OnTenantClick={handleTenantClick} />
                        <div className="d-flex justify-content-center my-2 w-full">
                            <Button className="bg-blue-600 w-full" onClick={() => setCurrentDomain('')}>
                                Show All
                            </Button>
                        </div>
                    </Col>
                    <Col md={10} className="h-100">
                        <CustomerDisplayEntry customerItems={currentItems} deleteCustomerItems={deleteCustomerItems} />
                    </Col>
                </Row>
            </Container>
        </PageLoader>
    )
}
