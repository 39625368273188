import React from 'react'
import { ERROR_404_ROUTE } from 'routes/path'
import { NotFound } from 'views/error/NotFound'
import { AppRouteConfig } from './utils'
// import {AppRouteConfig} from "../types/appRouteConfig";

export const errorRoutes: AppRouteConfig[] = [
    {
        path: ERROR_404_ROUTE,
        element: React.createElement(NotFound),
        isolated: true,
        isError: true,
    },
]
export default errorRoutes
