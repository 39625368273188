import React from 'react'
import { Login } from 'views/auth/Login'
import { BASE_ROUTE } from './path'
import errorRoutes from './error'
import authRoutes from './auth'
import dashboardRoutes from './dashboard'
// import {AppRouteConfig} from "../types/appRouteConfig";
import { RouteObject } from 'react-router-dom'
import { AppRouteConfig } from './utils'

const baseRoute: AppRouteConfig[] = [
    {
        path: BASE_ROUTE,
        element: React.createElement(Login),
    },
]

export default [...baseRoute, ...errorRoutes, ...authRoutes, ...dashboardRoutes]
