import React, { useEffect, useState } from 'react'
import { Chart } from './Chart'
import { TenantSideBar } from 'widgets/TenantSideBar'
import { useAppDispatch, useAppSelector } from 'store'
import { getPageViews, getTransactions, getTransactionStats } from 'store/analytics'
import { Analytics } from './Analytics'
import { Button, Col, Container, Row } from 'react-bootstrap'
import 'views/css/page_control.css'
import { TransactionTable } from './TransactionTable'
import { TimeFilters } from '../../widgets/UtilWidgets'

export default function Dashboard() {
    const dispatch = useAppDispatch()

    const [currentDomain, setCurrentDomain] = useState('')
    const [startDate, setStartDate] = useState(new Date(0))
    const [endDate, setEndDate] = useState(new Date())
    const { tenants, isLoadingTenants } = useAppSelector((state) => state.tenant)
    const { transactionStat, transactions, pageViews, isLoadingAnalytics } = useAppSelector((state) => state.analytics)

    // useEffect(() => {
    //     dispatch(getTenants())
    // }, [])

    useEffect(() => {
        if (tenants.length > 0) {
            handleTenantClick(tenants[0].domain)
        }
    }, [tenants])

    useEffect(() => {
        handleTenantClick(currentDomain || tenants[0].domain)
    }, [startDate, endDate])

    const handleTenantClick = async (domain: string) => {
        console.log('click domain: ' + domain)
        setCurrentDomain(domain)

        dispatch(getTransactionStats({ domain, startDate, endDate }))
        dispatch(getTransactions({ domain, startDate, endDate }))
        dispatch(getPageViews({ domain, startDate, endDate }))
    }

    const handleStartDateChange = (date: Date) => {
        setStartDate(date)
    }

    const handleEndDateChange = (date: Date) => {
        setEndDate(date)
    }

    return (
        <Container fluid className="h-100">
            <Row className="h-100">
                <Col md={2} className="d-flex flex-column h-100 page-height">
                    <TenantSideBar tenants={tenants} currentDomain={currentDomain} OnTenantClick={handleTenantClick} />
                </Col>

                <Col md={10} className="h-100">
                    <>
                        <TimeFilters startDate={startDate} OnStartDateChange={handleStartDateChange} endDate={endDate} OnEndDateChange={handleEndDateChange} />
                    </>
                    {!isLoadingAnalytics && (
                        <div className="page-height overflow-auto">
                            {currentDomain !== '' && <Chart domain={currentDomain} stats={transactionStat} />}
                            {currentDomain !== '' && <TransactionTable transactionItems={transactions} />}
                        </div>
                    )}
                </Col>
            </Row>
        </Container>
    )
}
