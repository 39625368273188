import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { getSizeChartRequest } from 'services/sizes'
import { RootState } from 'store'

/************************************************************************************************
 *
 *                                Types and Interfaces
 *
 ************************************************************************************************/

interface SizeState {
    sizecharts: SizeChart[]
    clothItems: ClothItem[]
    isLoading: boolean
    errors: any[]
}

interface SizeChart {
    cloth_type: string
    gender: string
    measure_map: any
    sizes: any
    uuid: string
}

interface ClothItem {
    disp_measure: any
    gender: string
    image_url: string
    name: string
    size_chart: string
    uuid: string
}

interface ListSizeChartResponse {
    data: SizeChart[]
    error: any[]
    message: string
    status: boolean
}

interface ListClothItemResponse {
    data: ClothItem[]
    error: any[]
    message: string
    status: boolean
}

export const initialState: SizeState = {
    sizecharts: [],
    clothItems: [],
    isLoading: true,
    errors: [],
}

/************************************************************************************************
 *
 *                                          Functions
 *
 ************************************************************************************************/

export const getSizeCharts = createAsyncThunk<SizeChart[], string>('size/size_chart', async (domain: string, { dispatch }) => {
    dispatch(setIsLoading(true))

    return getSizeChartRequest(domain)
        .then((res: ListSizeChartResponse) => {
            dispatch(setSizeCharts(res.data))
            return res.data
        })
        .catch((err: any) => {
            dispatch(setError([err.response.data.message]))
        })
        .finally(() => dispatch(setIsLoading(false))) as Promise<SizeChart[]>
})

export const sizeSlice = createSlice({
    name: 'size',
    initialState,
    reducers: {
        setSizeCharts: (state, { payload }: PayloadAction<SizeChart[]>) => {
            state.sizecharts = payload
        },
        setError: (state, { payload }: PayloadAction<any>) => {
            state.errors = payload
        },
        setIsLoading: (state, { payload }: PayloadAction<boolean>) => {
            state.isLoading = payload
        },
    },
})

export const { setSizeCharts, setIsLoading, setError } = sizeSlice.actions

export const sizeSelector = (state: RootState) => state.size

export default sizeSlice.reducer
