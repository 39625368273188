/*
 * Author: dizhong zhu
 * Date: 17/03/2023
 */

import axios from 'axios'
import { APIThrowError } from './apiErros'

const Api_DeleteAccount = (email, token) => {
    const config = {
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
        },
    }

    return axios
        .delete(`${process.env.REACT_APP_API_ENDPOINT}/merchant/account?email=${email}`, config)
        .then((response) => {
            return true
        })
        .catch((error) => {
            APIThrowError(error)
        })
}

const Api_GetAccountInfo = (token) => {
    const config = {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    }

    return axios
        .get(`${process.env.REACT_APP_API_ENDPOINT}/merchant/account`, config)
        .then((response) => {
            // console.log(response.data)
            if (response.data.status === true) {
                return response.data?.data
            }
        })
        .catch((error) => {
            APIThrowError(error)
        })
}

const Api_ForgetPassword = (email) => {
    return axios
        .get(`${process.env.REACT_APP_API_ENDPOINT}/merchant/forgot-password?email=${email}`)
        .then()
        .catch((error) => {
            APIThrowError(error)
        })
}

const Api_ResetPassword = (token, email, password) => {
    const data = {
        email: email,
        password: password,
    }
    const config = {
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
        },
    }

    return axios
        .post(`${process.env.REACT_APP_API_ENDPOINT}/merchant/reset-password`, data, config)
        .then()
        .catch((error) => {
            APIThrowError(error)
        })
}

export { Api_DeleteAccount, Api_GetAccountInfo, Api_ForgetPassword, Api_ResetPassword }
