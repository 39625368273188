/*
 * Author: dizhong zhu
 * Date: 10/10/2024
 */

import { TimeFilters } from 'widgets/UtilWidgets'
import { GarmentTaggingT } from 'store/garment-tagging'
import React, { Component } from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import { GarmentTaggingDispTable } from './GarmentTable'

interface props {
    garmentTaggingItems: GarmentTaggingT[]
    deleteGarmentTaggingItems: (item: GarmentTaggingT[]) => Promise<void>
}

interface state {
    startDate: any
    endDate: any
    filterItems: GarmentTaggingT[]
}

export class GarmentTaggingDisplayEntry extends Component<props, state> {
    private isCancelled = false

    constructor(props: props) {
        super(props)
        this.state = {
            startDate: null,
            endDate: null,
            filterItems: props.garmentTaggingItems,
        }
    }

    componentDidUpdate(prevProps: props, prevState: state) {
        if (prevProps.garmentTaggingItems !== this.props.garmentTaggingItems || prevState.startDate !== this.state.startDate || prevState.endDate !== this.state.endDate) {
            this.updateFilteredData()
        }
    }

    updateFilteredData = () => {
        const { garmentTaggingItems } = this.props
        const { startDate, endDate } = this.state
        let filterItems = garmentTaggingItems

        if (startDate) {
            filterItems = filterItems.filter((item) => new Date(item.created_at) >= startDate)
        }

        if (endDate) {
            filterItems = filterItems.filter((item) => new Date(item.created_at) <= endDate)
        }

        this.setState({ filterItems: filterItems })
    }

    handleStartDateChange = (date: any) => {
        this.setState({ startDate: date })
    }

    handleEndDateChange = (date: any) => {
        this.setState({ endDate: date })
    }

    render() {
        const { filterItems, startDate, endDate } = this.state
        return (
            <React.Fragment>
                <Container fluid className="h-100 d-flex flex-column">
                    <Row>
                        <Col md={8}>
                            <TimeFilters startDate={startDate} OnStartDateChange={this.handleStartDateChange} endDate={endDate} OnEndDateChange={this.handleEndDateChange} />
                        </Col>
                    </Row>
                    <Row className="flex-grow-1 overflow-hidden">
                        <Col className="h-100 overflow-auto" style={{ paddingRight: 0, paddingLeft: 0 }}>
                            <GarmentTaggingDispTable garmentTaggingItems={filterItems} handleDelete={this.props.deleteGarmentTaggingItems} />
                        </Col>
                    </Row>
                </Container>
            </React.Fragment>
        )
    }
}
