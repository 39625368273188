/*
 * Author: dizhong zhu
 * Date: 10/10/2024
 */

import { useEffect, useState } from 'react'
import { Button, Col, Container, Row } from 'react-bootstrap'
import { TenantSideBar } from 'widgets/TenantSideBar'
import { useAppDispatch, useAppSelector } from 'store'
import PageLoader from 'components/PageLoader'
import { delGarmentTagging, GarmentTaggingT, listGarmentTagging } from 'store/garment-tagging'
import { GarmentTaggingDisplayEntry } from './DisplayArea/GarmentEntry'

export const GarmentTagging = () => {
    const dispatch = useAppDispatch()

    const [currentDomain, setCurrentDomain] = useState('')
    const [currentItems, setCurrentItems] = useState<GarmentTaggingT[]>([])

    const { tenants } = useAppSelector((state) => state.tenant)
    const { garmentTaggingItems, isGarmentTaggingLoading } = useAppSelector((state) => state.garmentTagging)

    useEffect(() => {
        dispatch(listGarmentTagging())
    }, [])

    useEffect(() => {
        const filterItems = filterItemByDomain(currentDomain)
        setCurrentItems(filterItems)
    }, [garmentTaggingItems, currentDomain])

    const filterItemByDomain = (domain: string) => {
        if (domain === '') {
            return garmentTaggingItems
        }
        const filteredData = garmentTaggingItems.filter((item: any) => item.domain === domain)
        return filteredData.map((scan, index) => ({ ...scan, ID: index }))
    }

    const deleteGarmentTaggingItems = async (items: GarmentTaggingT[]) => {
        await dispatch(delGarmentTagging(items))
        dispatch(listGarmentTagging())
    }

    const handleTenantClick = async (domain: string) => {
        setCurrentDomain(domain)
    }

    return (
        <PageLoader open={isGarmentTaggingLoading}>
            <Container fluid className="h-100">
                <Row className="h-100">
                    {/* The col show tenants and clickable*/}
                    <Col md={2} className="d-flex flex-column h-100 page-height">
                        <TenantSideBar tenants={tenants} currentDomain={currentDomain} OnTenantClick={handleTenantClick} />
                        <div className="d-flex justify-content-center my-2 w-full">
                            <Button className="bg-blue-600 w-full" onClick={() => setCurrentDomain('')}>
                                Show All
                            </Button>
                        </div>
                    </Col>
                    <Col md={10} className="h-100">
                        <GarmentTaggingDisplayEntry garmentTaggingItems={currentItems} deleteGarmentTaggingItems={deleteGarmentTaggingItems} />
                    </Col>
                </Row>
            </Container>
        </PageLoader>
    )
}
