import Suspense from 'components/Suspense'
import PageLoader from 'components/PageLoader'
import { useAppSelector } from 'store'
import { NavigationBar } from './NavigationBar'
import { useLocation } from 'react-router-dom'
import { Toaster } from 'react-hot-toast'
import { getCurrentRouteContext } from 'routes/utils'

interface Props {
    children: React.ReactNode
}

const Layout = ({ children }: Props) => {
    const location = useLocation()

    const { pageLoader } = useAppSelector((state) => state.layout)
    const matched = getCurrentRouteContext(location)

    return (
        <Suspense>
            <PageLoader open={pageLoader}>
                <Toaster position="top-right" />
                {matched.showNav ? <NavigationBar>{children}</NavigationBar> : children}
            </PageLoader>
        </Suspense>
    )
}

export default Layout
