interface ErrorCardProps {
    text: string
}

const ErrorCard = ({ text }: ErrorCardProps) => {
    return (
        <div className="flex gap-2 md:gap-4 align-middle border-1 w-full mx-auto px-4 my-3 text-center shadow-md rounded-sm text-xs md:text-sm bg-red-100 text-gray-700 mt-3 transition duration-200 ease-in">
            <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 text-red-500 mt-3" viewBox="0 0 20 20" fill="currentColor">
                <path
                    fillRule="evenodd"
                    d="M10 1.944A11.954 11.954 0 012.166 5C2.056 5.649 2 6.319 2 7c0 5.225 3.34 9.67 8 11.317C14.66 16.67 18 12.225 18 7c0-.682-.057-1.35-.166-2.001A11.954 11.954 0 0110 1.944zM11 14a1 1 0 11-2 0 1 1 0 012 0zm0-7a1 1 0 10-2 0v3a1 1 0 102 0V7z"
                    clipRule="evenodd"
                />
            </svg>
            <p className="capitalize mt-3">{text}</p>
        </div>
    )
}

export default ErrorCard
