import React, { useEffect, useState } from 'react'
import Alert from 'react-bootstrap/Alert'
import 'bootstrap/dist/css/bootstrap.min.css'
import { CloseButton } from 'react-bootstrap'
import { createRoot } from 'react-dom/client'

const BottomAlert = ({ message, variant, onClose }) => {
    const [show, setShow] = useState(true)

    const alertStyle = {
        position: 'fixed',
        bottom: 0,
        left: 50,
        right: 50,
        zIndex: 9999,
    }

    useEffect(() => {
        const timer = setTimeout(() => {
            setShow(false)
            onClose()
        }, 8000)

        return () => {
            clearTimeout(timer)
        }
    }, [])

    const handleClose = () => {
        setShow(false)
        onClose()
    }

    return (
        show && (
            <Alert style={alertStyle} variant={variant}>
                <div className="d-flex justify-content-between">
                    <span>{message}</span>
                    <CloseButton onClick={handleClose} />
                </div>
            </Alert>
        )
    )
}

const displayAlert = (message, variant) => {
    const alertContainer = document.createElement('div')
    document.body.appendChild(alertContainer)
    const root = createRoot(alertContainer)

    const removeAlert = () => {
        root.unmount()

        document.body.removeChild(alertContainer)
    }

    root.render(
        <React.StrictMode>
            <BottomAlert message={message} variant={variant} onClose={removeAlert} />
        </React.StrictMode>,
        alertContainer
    )
}

const DisplaySuccess = (message) => {
    displayAlert(message, 'success')
}

const DisplayAlert = (message) => {
    displayAlert(message, 'danger')
}

export { DisplayAlert, DisplaySuccess }

// Usage:
// DisplaySuccessAlert("This is a success message"); // Green
// DisplayDangerAlert("This is a danger message"); // Red
