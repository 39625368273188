import HelperText from 'components/HelperText'
import { Field, Form, Formik, FormikProps } from 'formik'
import { Link, NavLink, NavigateFunction, useNavigate } from 'react-router-dom'
import { useAppDispatch, useAppSelector } from 'store'
import { LoginInput, processLogin } from 'store/auth'
import * as Yup from 'yup'
import { DASHBOARD_ROUTE, FORGOT_PASSWORD_ROUTE, REGISTER_ROUTE, TENANTS_ROUTE } from 'routes/path'

import ErrorCard from 'components/ErrorCard'
import Loader from 'components/Loader'
import { Logo } from 'assets/png'
import toast from 'react-hot-toast'

export const Login = () => {
    const navigate: NavigateFunction = useNavigate()
    const dispatch = useAppDispatch()

    const { errors, isLoading } = useAppSelector((state) => state.auth)

    const validationSchema: Yup.Schema<LoginInput> = Yup.object().shape({
        email: Yup.string().email().required(),
        password: Yup.string().required(),
    })
    const initialValues: LoginInput = { email: '', password: '' }

    const handleSubmit = (data: LoginInput) => {
        dispatch(processLogin(data)).then((res) => {
            const { payload } = res
            if (payload) {
                toast.success('Your account successfully logged in')
                navigate(TENANTS_ROUTE, { replace: true })
            }
        })
    }

    return (
        <section className="min-h-screen py-16 lg:pb-32 bg-gray-100 overflow-hidden">
            <div className="container px-4 mx-auto">
                <div className="max-w-lg mx-auto">
                    <div className="text-center mb-3">
                        <div className="grid my-2">
                            <img className="mx-auto" src={Logo} alt="Logo" width={'40%'} />
                        </div>
                        <h2 className="font-heading mt-5 text-2xl tracking-tighter">Welcome Back</h2>
                        <p className="text-md tracking-tight">Kindly fill in your details to sign in to your account</p>
                    </div>
                    <div className="mb-6 py-5 px-3 bg-white border border-gray-200 rounded-2xl">
                        {errors.length > 0 && <ErrorCard text={errors[0]} />}
                        <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={(values: LoginInput) => handleSubmit(values)}>
                            {({ handleChange, handleSubmit, values, errors, touched, isValid, handleBlur }: FormikProps<LoginInput>) => (
                                <Form className="flex flex-col items-start w-full mt-2" onSubmit={handleSubmit}>
                                    <div className="w-full p-2">
                                        <label className="mb-1 text-sm text-[#5C5C80]" htmlFor="email">
                                            Email
                                        </label>
                                        <Field
                                            className="w-full p-2 text-md text-gray-500 border border-gray-200 rounded-md placeholder:text-sm placeholder:text-[#A3A3C2]"
                                            type="email"
                                            onBlur={handleBlur('email')}
                                            onChange={handleChange('email')}
                                            value={values.email}
                                            name="email"
                                            placeholder="admin@company.com"
                                        />
                                        {errors.email && touched.email ? <HelperText valid={false} text={errors.email} /> : null}
                                    </div>
                                    <div className="w-full p-2">
                                        <label className="mb-1 text-sm text-[#5C5C80]" htmlFor="password">
                                            Password
                                        </label>
                                        <Field
                                            className="w-full p-2 text-md text-gray-500 border border-gray-200 rounded-md placeholder:text-sm placeholder:text-[#A3A3C2]"
                                            type="password"
                                            onBlur={handleBlur('password')}
                                            onChange={handleChange('password')}
                                            value={values.password}
                                            name="password"
                                            placeholder="*****"
                                        />
                                        {errors.password && touched.password ? <HelperText valid={false} text={errors.password} /> : null}
                                        <div className="text-right">
                                            <Link to={FORGOT_PASSWORD_ROUTE} className="text-primary-400 w-full text-xs">
                                                Forgot Password?
                                            </Link>
                                        </div>
                                        {isLoading && <Loader />}
                                        {!isLoading && (
                                            <button className="w-full mt-5 py-3 bg-[#0d6efd] text-sm font-semibold text-white rounded-md" disabled={!isValid} type="submit">
                                                Login
                                            </button>
                                        )}
                                    </div>
                                </Form>
                            )}
                        </Formik>
                    </div>
                    <p className="text-center text-gray-600 font-medium text-sm">
                        <span>Don't have an account?</span>
                        <NavLink to={REGISTER_ROUTE} className="text-[#0d6efd] mx-1 hover:text-indigo-600 text-sm transition duration-200">
                            Create Free Account
                        </NavLink>
                    </p>
                </div>
            </div>
        </section>
    )
}
