import React, { useCallback, useEffect, useRef, useState } from 'react'
import { Form, Button, Image } from 'react-bootstrap'
import '../tenants.css'
import { Api_UpdateTenantConfigurationImage, Api_UpdateTenantConfigurationMeasureConfig } from 'apis/tenants'
import { APIErrorHandler } from 'apis/apiErros'
import ReactAce from 'react-ace'
import 'ace-builds/src-noconflict/mode-json'
import 'ace-builds/src-noconflict/theme-github'
import 'ace-builds/webpack-resolver'
import { fetchImage } from 'utils/data_process'
import { useAppDispatch, useAppSelector } from 'store'
import {
    getConfigurations,
    setMeasureConfiguration,
    updateButtonHiddenConfiguration,
    updateEmailConfiguration,
    updateReconstructionConfiguration,
    updateVideoConfiguration,
    updateWidgetConfiguration,
} from 'store/configuration'
import Loader from 'components/Loader'
import toast from 'react-hot-toast'
import Tags from '@yaireo/tagify/dist/react.tagify'
import '@yaireo/tagify/dist/tagify.css'

interface props {
    domain: string
    configs: any
}

interface UploadedImageT {
    blob: any
    name: string
    type: string
}

export const WidgetConfiguration: React.FC<props> = ({ domain, configs }) => {
    // const [widgetCfg, setWidgetCfg] = useState({})
    const [uploadedImage, setUploadedImage] = useState<UploadedImageT | null>(null)
    const [uploadingMeasurementConfig, setUploadingMeasurementConfig] = useState(false)
    const [uploadMeasurementErr, setUploadMeasurementErr] = useState('')
    const [emailList, setEmailList] = useState<string[]>([])
    const [localWidgetConfig, setLocalWidgetConfig] = useState('{}') // Local state for widget configuration
    const handleError = APIErrorHandler()

    const { videoConfiguration, reconstructionConfiguration, buttonHiddenConfiguration, widgetConfiguration, measureConfiguration, emailConfiguration, isUpdatingEmailConfiguration } = useAppSelector(
        (state) => state.configuration
    )
    const dispatch = useAppDispatch()

    const emailListInputRef = useRef<any>(null)

    useEffect(() => {
        // const emailConfig = configs?.find((config) => config.name === 'email_config')
        // const widgetConfig = configs?.find((config) => config.name === 'widget_config')
        //
        // setWidgetCfg({
        //     email_config: emailConfig?.value || '',
        //     widget_config: JSON.stringify(widgetConfig?.value, null, 2) || '',
        // })
        const fetchData = async () => {
            const imageConfig = configs?.find((config: any) => config.name === 'image_cover')
            if (imageConfig) {
                const image = await fetchImage(imageConfig?.value)

                let processedImageBlob = ''
                if (image.type === '.svg') {
                    processedImageBlob = image.dataUrl.replace('data:application/octet-stream', 'data:image/svg+xml')
                } else {
                    processedImageBlob = image.dataUrl.replace('data:application/octet-stream', `data:${image.type}`)
                }
                setUploadedImage({
                    blob: processedImageBlob,
                    name: 'image_cover',
                    type: image.type,
                })
            }
        }

        fetchData().then()
        setLocalWidgetConfig(JSON.stringify(widgetConfiguration, null, 2))
    }, [configs])

    const handleImageChange = (e: any) => {
        const file = e.target.files?.[0]
        const reader = new FileReader()

        if (file) {
            const fileNameParts = file.name.split('.')
            const extension = '.' + fileNameParts.pop()
            const fileNameWithoutExtension = fileNameParts.join('.')

            reader.onloadend = () => {
                setUploadedImage({
                    blob: reader.result,
                    name: fileNameWithoutExtension,
                    type: extension,
                })
            }
            reader.readAsDataURL(file)
        }
    }

    const readFile = (event: React.ChangeEvent<HTMLInputElement>) => {
        const fileReader = new FileReader()
        const { files } = event.target

        if (files?.[0]) {
            fileReader.readAsText(files[0], 'UTF-8')
            fileReader.onload = (e: any) => {
                const content = e.target.result
                try {
                    dispatch(setMeasureConfiguration(JSON.parse(content)))
                    setUploadMeasurementErr('')
                } catch (e) {
                    setUploadMeasurementErr('Please upload a valid json file')
                }
            }
        }
    }

    const uploadImage = async () => {
        try {
            const token = localStorage.getItem('token')
            await Api_UpdateTenantConfigurationImage(domain, token, uploadedImage)
        } catch (error) {
            handleError(error)
        }
    }

    const updateConfiguration = async () => {
        setUploadingMeasurementConfig(true)
        const token = localStorage.getItem('token')
        Api_UpdateTenantConfigurationMeasureConfig(domain, token, measureConfiguration)
            .then(() => {
                toast.success('Configurations updated')
                dispatch(getConfigurations(domain))
            })
            .catch((err) => {
                handleError(err)
            })
            .finally(() => setUploadingMeasurementConfig(false))
    }

    const onEmailListChange = useCallback((e: any) => {
        const emails = []
        try {
            const data = JSON.parse(e.detail.value)
            for (let i = 0; i < data.length; i++) {
                const email = data[i]
                emails.push(email.value)
            }
            setEmailList(emails)
        } catch (error) {
            setEmailList([])
        }
    }, [])

    const handleWidgetConfigChange = (newConfig: any) => {
        setLocalWidgetConfig(newConfig)
    }

    const updateWidgetConfig = () => {
        try {
            const parsedConfig = JSON.parse(localWidgetConfig)
            dispatch(updateWidgetConfiguration({ domain, value: parsedConfig }))
            toast.success('Widget configuration updated')
        } catch (error) {
            toast.error('Invalid JSON format')
        }
    }

    return (
        <Form className="bold-form-labels">
            <Form.Group className="mb-3">
                <Form.Label>Data Email</Form.Label>
                <div className="text-muted text-sm mb-2">
                    <label className="relative inline-flex items-center cursor-pointer mt-2">
                        <input
                            type="checkbox"
                            value=""
                            checked={emailConfiguration?.receive_email}
                            className="sr-only peer"
                            onChange={() => {
                                dispatch(
                                    updateEmailConfiguration({
                                        domain,
                                        value: { receive_email: !emailConfiguration?.receive_email, email_list: emailList },
                                    })
                                )
                            }}
                        />
                        <div className="w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-1 peer-focus:ring-blue-300  rounded-full peer  peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all  peer-checked:bg-blue-600"></div>
                        <span className="ml-3 text-sm font-medium text-gray-900 ">Toggle if you want to receive data when scan complete</span>
                    </label>
                </div>
                {emailConfiguration?.receive_email && (
                    <>
                        <div className="flex gap-2">
                            <Tags
                                tagifyRef={emailListInputRef}
                                defaultValue={emailConfiguration.email_list.join(',')}
                                onChange={onEmailListChange}
                                className="w-48"
                                placeholder="Enter comma seperated emails"
                            />
                            {isUpdatingEmailConfiguration ? (
                                <Loader />
                            ) : (
                                <Button
                                    className="bg-blue-600 px-3"
                                    size="sm"
                                    disabled={emailList.length < 1}
                                    onClick={() => {
                                        dispatch(
                                            updateEmailConfiguration({
                                                domain,
                                                value: { receive_email: true, email_list: emailList },
                                            })
                                        )
                                    }}
                                >
                                    Save
                                </Button>
                            )}
                        </div>

                        <Form.Text className="text-muted">You will receive scan data in these emails everytime user complete a scan.</Form.Text>
                    </>
                )}
            </Form.Group>

            <Form.Group className="mb-3 mt-6" controlId="video_option">
                <Form.Label>Video Configuration</Form.Label>
                <div className="text-muted text-sm">
                    <label className="relative inline-flex items-center cursor-pointer mt-2">
                        <input
                            type="checkbox"
                            value=""
                            checked={videoConfiguration?.keep_video}
                            className="sr-only peer"
                            onChange={() => {
                                dispatch(updateVideoConfiguration({ domain, value: { keep_video: !videoConfiguration?.keep_video } }))
                            }}
                        />
                        <div className="w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-1 peer-focus:ring-blue-300  rounded-full peer  peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all  peer-checked:bg-blue-600"></div>
                        <span className="ml-3 text-sm font-medium text-gray-900 ">Toggle if you want to keep the video</span>
                    </label>
                </div>
            </Form.Group>
            <Form.Group className="mb-3 mt-6" controlId="reconstruction_option">
                <Form.Label>Reconstruction Configuration</Form.Label>
                <div className="text-muted text-sm">
                    <label className="relative inline-flex items-center cursor-pointer mt-2">
                        <input
                            type="checkbox"
                            value=""
                            checked={reconstructionConfiguration?.use_optimisation}
                            className="sr-only peer"
                            onChange={() => {
                                dispatch(
                                    updateReconstructionConfiguration({
                                        domain,
                                        value: { use_optimisation: !reconstructionConfiguration?.use_optimisation },
                                    })
                                )
                            }}
                        />
                        <div className="w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-1 peer-focus:ring-blue-300  rounded-full peer  peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all  peer-checked:bg-blue-600"></div>
                        <span className="ml-3 text-sm font-medium text-gray-900 ">Toggle if you want to optimize reconstruction</span>
                    </label>
                </div>
            </Form.Group>
            <Form.Group className="mb-3 mt-6" controlId="hide_button_option">
                <Form.Label>Buton Configuration</Form.Label>
                <div className="text-muted text-sm">
                    <label className="relative inline-flex items-center cursor-pointer mt-2">
                        <input
                            type="checkbox"
                            value=""
                            checked={buttonHiddenConfiguration?.hide_button}
                            className="sr-only peer"
                            onChange={() => {
                                dispatch(
                                    updateButtonHiddenConfiguration({
                                        domain,
                                        value: { hide_button: !buttonHiddenConfiguration?.hide_button },
                                    })
                                )
                            }}
                        />
                        <div className="w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-1 peer-focus:ring-blue-300  rounded-full peer  peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all  peer-checked:bg-blue-600"></div>
                        <span className="ml-3 text-sm font-medium text-gray-900 ">Toggle if you want to hide the aistetic sizer helper in your website</span>
                    </label>
                </div>
            </Form.Group>
            <Form.Group className="mb-3 mt-12" controlId="widget_config">
                <Form.Label>Widget Config</Form.Label>
                <ReactAce
                    mode="json"
                    theme="github"
                    value={localWidgetConfig}
                    onChange={handleWidgetConfigChange}
                    style={{ width: '100%' }}
                    setOptions={{
                        showLineNumbers: true,
                        tabSize: 2,
                    }}
                />
                <Button className="bg-blue-600 " size="sm" onClick={updateWidgetConfig}>
                    Update
                </Button>
            </Form.Group>
            <div className="mt-14">
                <Form.Group className="mb-5" controlId="measurement_config">
                    <Form.Label>Measurement Config</Form.Label>
                    <ReactAce
                        mode="json"
                        theme="github"
                        value={JSON.stringify(measureConfiguration, null, 2)}
                        readOnly
                        style={{ width: '100%' }}
                        setOptions={{
                            showLineNumbers: true,
                            tabSize: 2,
                        }}
                    />
                    <Form.Label className="mt-2">Update measurement config</Form.Label>
                    <Form.Control type="file" size="sm" onChange={readFile} />
                    <p className="text-red-400 font-bold text-xs mt-2 mb-3">{uploadMeasurementErr}</p>
                    {uploadingMeasurementConfig ? (
                        <Loader />
                    ) : (
                        <Button className="bg-blue-600" size="sm" disabled={measureConfiguration === ''} onClick={updateConfiguration}>
                            Upload
                        </Button>
                    )}
                </Form.Group>
            </div>
            <Form.Group className="mb-3" controlId="img_cover">
                <Form.Label>Image Cover Preview (Please make aspect ratio(height:width)=13:8)</Form.Label>
                <Form.Control type="file" size="sm" onChange={handleImageChange} />
                {/* Display uploaded image */}
                <Image src={uploadedImage?.blob} alt="Invalide image" />
                <Button className="bg-blue-600" size="sm" disabled={!uploadedImage?.blob} onClick={uploadImage}>
                    Upload
                </Button>
            </Form.Group>
        </Form>
    )
}
