import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'

import { loginRequest } from 'services/auth'
import cookie from 'services/cookie'
import { RootState } from 'store'

/************************************************************************************************
 *
 *                                Types and Interfaces
 *
 ************************************************************************************************/

export interface LoginInput {
    email: string
    password: string
}

export interface LoginResponse {
    data: AuthUser
}

export interface AuthUser {
    id?: string
    token?: string
}

interface AuthState {
    isLoading: boolean
    isAuth: boolean
    errors: string[]
    user?: AuthUser | null
}

export const initialState: AuthState = {
    isLoading: false,
    isAuth: false,
    errors: [],
    user: null,
}

/************************************************************************************************
 *
 *                                          Functions
 *
 ************************************************************************************************/

export const processLogin = createAsyncThunk<LoginResponse, LoginInput>('auth/login', async (loginData: LoginInput, { dispatch }) => {
    dispatch(setAuthLoading(true))
    dispatch(setAuthError([]))

    return loginRequest(loginData)
        .then((response: LoginResponse) => {
            dispatch(setAuthSuccess(response.data))

            cookie.set('auth_user', {
                ...response.data,
            })

            localStorage.setItem('token', response.data!.token!)

            return response.data
        })
        .catch((err) => {
            dispatch(setAuthError([err.response.data.message]))
        })
        .finally(() => dispatch(setAuthLoading(false))) as Promise<LoginResponse>
})

export const logout = createAsyncThunk('auth/logout', async (_, { dispatch }) => {
    console.log('logout')
    dispatch(setLogOut())
    cookie.deleteAll()
    localStorage.clear()
})

export const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        setAuthLoading: (state, { payload }: PayloadAction<boolean>) => {
            state.isLoading = payload
        },
        setAuthSuccess: (state, { payload }: PayloadAction<AuthUser>) => {
            state.user = payload
            state.isAuth = true
            state.isLoading = false
        },
        setUserData: (state, { payload }: PayloadAction<AuthUser>) => {
            state.user = payload
        },
        setAuthError: (state, { payload }: PayloadAction<any>) => {
            state.errors = payload
            state.isAuth = false
        },
        setLogOut: (state) => {
            state.isAuth = false
            state.errors = []
            state.isLoading = false
            state.user = undefined
        },
        authReset: (state: AuthState) => {
            state.user = initialState.user
            state.errors = initialState.errors
            state.isLoading = initialState.isLoading
        },
    },
})

export const { setAuthSuccess, setUserData, setAuthLoading, authReset, setAuthError, setLogOut } = authSlice.actions

export const authSelector = (state: RootState) => state.auth

export default authSlice.reducer
