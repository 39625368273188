/*
 * Author: dizhong zhu
 * Date: 16/09/2024
 */

import { CompositeFilterDescriptor, DataResult, filterBy, process, State } from '@progress/kendo-data-query'
import { getter } from '@progress/kendo-react-common'
import React, { Component, createRef, RefObject } from 'react'
import { ExcelExport, ExcelExportColumn } from '@progress/kendo-react-excel-export'
import { getSelectedState, Grid, GridColumn as Column, GridDataStateChangeEvent, GridHeaderSelectionChangeEvent, GridSelectionChangeEvent, GridToolbar } from '@progress/kendo-react-grid'
import { DebouncedInput } from 'components/DebouncedInput'
import { Button, OverlayTrigger, Tooltip } from 'react-bootstrap'
import { CustomerT } from 'store/customers'

interface props {
    customerItems: CustomerT[]
    handleDelete: (selectedRows: CustomerT[]) => Promise<void>
}

interface state {
    customerData: CustomerT[]
    dataState: State
    selectedState: { [id: string]: boolean | number[] }
    tooltipVisible: boolean
    target: any
    currentCellRowIndex: number | null
    currentCellColumnField: string | undefined
}

const DATA_ITEM_KEY = 'customer_id'
const SELECTED_FIELD = 'selected'
const idGetter = getter(DATA_ITEM_KEY)

export class CustomerDisplayTable extends Component<props, state> {
    // private _export = createRef<ExcelExport | null>();

    private readonly _export: RefObject<ExcelExport>
    private columns: any[] // Declare columns as a class property
    private hideTooltipTimeout: ReturnType<typeof setTimeout> | null = null

    constructor(props: props) {
        super(props)

        const { customerItems } = props

        const initialDataState: State = {
            take: 20,
            skip: 0,
            filter: undefined,
            sort: [{ field: 'time_stamp', dir: 'desc' }],
        }

        this.state = {
            customerData: customerItems,
            dataState: initialDataState,
            selectedState: {},
            tooltipVisible: false,
            target: null,
            currentCellRowIndex: null,
            currentCellColumnField: undefined,
        }

        this.columns = [
            {
                field: SELECTED_FIELD,
                width: 45,
                // headerSelectionValue: this.state.scanData.findIndex((item) => !this.state.selectedState[idGetter(item)]) === -1,
                sortable: false,
                filterable: false,
            },
            { field: 'customer_id', title: 'ID', width: 160 },
            { field: 'domain', title: 'Domain', width: 180 },
            { field: 'email', title: 'Email' },
            { field: 'first_name', title: 'First Name' },
            { field: 'last_name', title: 'Last Name' },
            { field: 'phone', title: 'Phone' },
            { field: 'transaction_count', title: 'Checkouts' },
            { field: 'quicksize_count', title: 'Quick Size' },
            { field: 'scan_count', title: 'Scans' },
        ]

        this._export = createRef<ExcelExport>()
    }

    showTooltip = (target: any, rowIndex: number, columnField: string | undefined) => {
        if (this.hideTooltipTimeout) {
            clearTimeout(this.hideTooltipTimeout)
        }
        this.setState({
            tooltipVisible: true,
            target,
            currentCellRowIndex: rowIndex,
            currentCellColumnField: columnField,
        })
    }

    hideTooltip = () => {
        this.hideTooltipTimeout = setTimeout(() => {
            this.setState({
                tooltipVisible: false,
                currentCellRowIndex: null,
                currentCellColumnField: undefined,
            })
        }, 300)
    }

    componentDidUpdate(prevProps: props) {
        if (prevProps.customerItems !== this.props.customerItems) {
            this.setState({ customerData: this.props.customerItems, selectedState: {} })
        }
    }

    excelExport = () => {
        if (this._export.current !== null) {
            this._export.current.save()
        }
    }

    handleDataStateChange = (event: GridDataStateChangeEvent) => {
        this.setState({ dataState: event.dataState })
    }

    onSelectionChange = (event: GridSelectionChangeEvent) => {
        const newSelectedState = getSelectedState({
            event,
            selectedState: this.state.selectedState,
            dataItemKey: DATA_ITEM_KEY,
        })
        this.setState({ selectedState: newSelectedState })
    }

    onHeaderSelectionChange = (event: GridHeaderSelectionChangeEvent) => {
        const checkboxElement: any = event.syntheticEvent.target
        const checked = checkboxElement.checked
        const newSelectedState = { ...this.state.selectedState }

        this.state.customerData.forEach((item) => {
            newSelectedState[idGetter(item)] = checked
        })
        this.setState({ selectedState: newSelectedState })
    }

    filterData = (value: any) => {
        const filter: CompositeFilterDescriptor = {
            logic: 'or',
            filters: this.columns
                .filter((column) => column.field && column.filterable !== false)
                .map((column) => ({
                    field: column.field,
                    operator: 'contains',
                    value: value,
                })),
        }
        this.setState({ customerData: filterBy(this.props.customerItems, filter) })
    }

    render() {
        const { customerData, dataState, selectedState } = this.state
        const { handleDelete } = this.props
        return (
            <>
                <ExcelExport fileName="quick_size.xlsx" data={customerData} ref={this._export}>
                    {this.columns
                        .filter((column) => column.field !== SELECTED_FIELD)
                        .map((col, idx) => (
                            <ExcelExportColumn key={idx} field={col.field} title={col.title} />
                        ))}
                </ExcelExport>
                <Grid
                    className="nowrap-grid"
                    data={{
                        ...(process(customerData, dataState) as DataResult),
                        data: process(customerData, dataState).data.map((item: CustomerT) => ({
                            ...item,
                            [SELECTED_FIELD]: selectedState[idGetter(item)],
                        })),
                    }}
                    dataItemKey={DATA_ITEM_KEY}
                    selectedField={SELECTED_FIELD}
                    selectable={{
                        enabled: true,
                        drag: false,
                        cell: false,
                        mode: 'multiple',
                    }}
                    onSelectionChange={this.onSelectionChange}
                    onHeaderSelectionChange={this.onHeaderSelectionChange}
                    pageable={{
                        type: 'input',
                        pageSizes: [5, 10, 15, 20, 30],
                        previousNext: true,
                    }}
                    resizable
                    skip={dataState.skip}
                    take={dataState.take}
                    total={customerData.length}
                    sortable
                    sort={dataState.sort}
                    onDataStateChange={this.handleDataStateChange}
                >
                    <GridToolbar>
                        <DebouncedInput value={''} onChange={(value) => this.filterData(value)} className="p-2 font-lg shadow border border-block" placeholder="Search all columns..." />
                        <OverlayTrigger placement="bottom" delay={{ show: 250, hide: 200 }} overlay={<Tooltip style={{ position: 'fixed' }}>Export all data to Excel</Tooltip>}>
                            <Button className="bg-blue-600" onClick={this.excelExport}>
                                Export to Excel
                            </Button>
                        </OverlayTrigger>
                        <Button
                            className="bg-blue-600"
                            disabled={Object.values(selectedState).filter((selected) => selected === true).length === 0}
                            onClick={(e) => {
                                if (window.confirm('Are you sure you want to delete the selected items?')) {
                                    const selectedItems = Object.entries(selectedState)
                                        .map(([key, value]) => customerData.find((item) => item.customer_id === key && value === true))
                                        .filter((item): item is CustomerT => item !== undefined)
                                    handleDelete(selectedItems)
                                }
                            }}
                        >
                            Delete: {Object.values(selectedState).filter((selected) => selected === true).length}
                        </Button>
                    </GridToolbar>
                    {this.columns.map((col, idx) => (
                        <Column key={idx} {...col} />
                    ))}
                </Grid>
            </>
        )
    }
}
