import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { delScansRequest, getScanDetailsRequest, getScansRequest, postScanURLRequest } from 'services/scan'
import { RootState } from 'store'

/************************************************************************************************
 *
 *                                Types and Interfaces
 *
 ************************************************************************************************/

export interface ScanT {
    uuid: string
    domain: string
    gender: string
    email: string
    first_name: string
    last_name: string
    source: string
    scan_at: string
    size: string
    status: string
    complete: boolean
    fails_reason: string
    order_id: string
}

interface ScanState {
    scans: ScanT[]
    activeScan: ScanDetailT | null
    isLoading: boolean
    isScanListLoading: boolean
    errors: any[]
}

interface ScanDetailT {
    measurements: Record<string, number>
    obj_64: any
    first_image: string
    user_info: {
        userInfo: ScanDetailUserInfo
    }
}

export interface ScanURLT {
    uuid: string
    files: { [key: string]: { url: string; filename: string } }
}

interface ScanDetailUserInfo {
    gender: string
    height: string
    weight: string
}

interface ListScanResponse {
    data: any
    error: any[]
    message: string
    status: boolean
}

interface ViewScanResponse {
    data: any
    error: any[]
    message: string
    status: boolean
}

export const initialState: ScanState = {
    scans: [],
    activeScan: null,
    isLoading: true,
    isScanListLoading: true,
    errors: [],
}

interface ScanDetailParams {
    domain: string
    id: string
}

/************************************************************************************************
 *
 *                                          Functions
 *
 ************************************************************************************************/

export const getScans = createAsyncThunk<ScanT[]>('scan/list', async (_, { dispatch }) => {
    dispatch(setScanListIsLoading(true))

    return getScansRequest()
        .then((res: ListScanResponse) => {
            let scanList: ScanT[] = []
            const scans = res.data
            let index = 0
            for (const key in scans) {
                const scan_with_domains = scans[key].map((scan: ScanT) => {
                    return { ...scan, ID: index++, domain: key }
                })
                scanList = [...scanList, ...scan_with_domains]
            }

            dispatch(setScans(scanList))
            return scanList
        })
        .catch((err: any) => {
            dispatch(setError([err.response.data.message]))
        })
        .finally(() => dispatch(setScanListIsLoading(false))) as Promise<ScanT[]>
})

export const delScans = createAsyncThunk<void, ScanT[]>('scan/delete', async (scans: ScanT[], { dispatch }) => {
    return delScansRequest(scans)
        .then((res: ListScanResponse) => {})
        .catch((err: any) => {
            dispatch(setError([err.response.data.message]))
        })
})

export const getScanDetails = createAsyncThunk<any, ScanDetailParams>('scan/details', async ({ domain, id }: any, { dispatch }) => {
    dispatch(setIsLoading(true))

    return getScanDetailsRequest(domain, id)
        .then((res: ViewScanResponse) => {
            dispatch(setActiveScan(res.data))
            return res.data
        })
        .catch((err: any) => {
            dispatch(setError([err.response.data.message]))
        })
        .finally(() => dispatch(setIsLoading(false))) as Promise<any>
})

export const getScanDownload = createAsyncThunk<any, ScanT[]>('scan/download', async (scans: ScanT[], { dispatch }) => {
    try {
        dispatch(setIsLoading(true))
        const response = await postScanURLRequest(scans)
        return response.data // Assuming response.data is of type ScanURLT[]
    } catch (err: any) {
        const errorMessage = err.response?.data?.message || 'Unknown error'
        dispatch(setError([errorMessage]))
    } finally {
        dispatch(setIsLoading(false))
    }
})

export const scanSlice = createSlice({
    name: 'scan',
    initialState,
    reducers: {
        setScans: (state, { payload }: PayloadAction<ScanT[]>) => {
            state.scans = payload
        },
        setActiveScan: (state, { payload }: PayloadAction<ScanDetailT>) => {
            state.activeScan = {
                ...payload,
                ...{
                    user_info: {
                        userInfo: {
                            gender: payload?.user_info?.userInfo?.gender,
                            height: payload?.user_info?.userInfo?.height + 'cm',
                            weight: payload?.user_info?.userInfo?.weight + 'kg',
                        },
                    },
                },
            }
        },
        setError: (state, { payload }: PayloadAction<any>) => {
            state.errors = payload
        },
        setIsLoading: (state, { payload }: PayloadAction<boolean>) => {
            state.isLoading = payload
        },
        setScanListIsLoading: (state, { payload }: PayloadAction<boolean>) => {
            state.isScanListLoading = payload
        },
    },
})

export const { setScans, setScanListIsLoading, setIsLoading, setError, setActiveScan } = scanSlice.actions

export default scanSlice.reducer
