import http from './http'
import { ListTenantResponse, ViewTenantResponse } from 'store/tenant'

export async function getTenantsRequest(): Promise<ListTenantResponse> {
    return (await http.get('/merchant/tenant')).data
}

export async function getTenantRequest(domain: string): Promise<ViewTenantResponse> {
    return (await http.get(`/merchant/tenant?domain=${domain}`)).data
}
