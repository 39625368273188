/*
 * Author: dizhong zhu
 * Date: 27/04/2023
 */

import { useState } from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom'

import { TenSettingGeneral } from './settings/TenSettingGeneral'
import { TenSettingSizeChart } from './settings/SettingSizeChart/TenSettingSizeChart'
import { TenSettingSideBar } from './settings/TenSettingSideBar'
import { TENANT_GENERAL, TENANT_SIZECHART } from 'common/routePaths'

function TenantSettings() {
    const location = useLocation()
    const domain = location.state?.domain
    const navigate = useNavigate()

    const sideBarItems = [
        { name: 'General', path: TENANT_GENERAL, component: TenSettingGeneral },
        { name: 'Size Chart', path: TENANT_SIZECHART, component: TenSettingSizeChart },
        // { name: 'Billing', path: TENANT_BILLING, component: TenSettingBilling },
    ]
    const [currentItem, setCurrentItem] = useState(sideBarItems[0].name)

    const handleSideBarClick = (item: any) => {
        setCurrentItem(item.name)
        navigate(item.path, { state: { domain } })
    }

    return (
        <Container fluid className="align-items-center justify-content-center">
            <Row className="h-100">
                {/* The col show tenants and clickable*/}
                <Col md={2} className="flex-column h-100">
                    <div className="flex-grow-1 overflow-auto">
                        <TenSettingSideBar items={sideBarItems} currentItem={currentItem} OnClick={handleSideBarClick} />
                    </div>
                </Col>
                <Col className="h-100 overflow-auto" style={{ height: '100%' }}>
                    <Routes>
                        <Route path="/" element={<TenSettingGeneral domain={domain} />} />
                        {sideBarItems.map((item, index) => (
                            <Route key={index} path={item.path} element={<item.component domain={domain} />} />
                        ))}
                    </Routes>
                </Col>
            </Row>
        </Container>
    )
}

export { TenantSettings }
