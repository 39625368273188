/*
 * Author: dizhong zhu
 * Date: 10/03/2023
 */

import { useState, useEffect } from 'react'
import { Card, Button, Container, Row, Col, Form } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom'
import './tenants.css'
import { useAppDispatch, useAppSelector } from 'store'
import { getTenants } from 'store/tenant'
import { ModalCreate } from './Modals'
import { TENANTS_ROUTE } from 'routes/path'
import { format, isBefore, subMonths } from 'date-fns'
import PageLoader from 'components/PageLoader'
import { setShowActiveTenants } from 'store/settings' // Import the action from the 'setting' slice

export const Tenants = () => {
    const dispatch = useAppDispatch()

    const { tenants, isLoadingTenants } = useAppSelector((state) => state.tenant)
    const { showActiveTenants } = useAppSelector((state) => state.settings)
    const [showCreate, setShowCreate] = useState(false)
    // const [showActiveTenants, setShowActiveTenants] = useState(false);
    const navigate = useNavigate()

    // useEffect(() => {
    //     dispatch(getTenants())
    // }, [])

    useEffect(() => {
        dispatch(getTenants())
    }, [showActiveTenants])

    const handleCreateTenant = async () => {
        setShowCreate(false)
        dispatch(getTenants())
    }

    const handleShowActiveTenants = (active: boolean) => {
        dispatch(setShowActiveTenants(active))
    }

    const handleCardClick = (tenant: any, index: any) => {
        navigate(`${TENANTS_ROUTE}/${index}`, { state: { domain: tenant.domain } })
    }

    const getLastActivityColor = (lastActivity: string) => {
        const lastActivityDate = new Date(lastActivity)
        const oneMonthAgo = subMonths(new Date(), 1)
        return isBefore(lastActivityDate, oneMonthAgo) ? 'red' : 'green'
    }

    return (
        <PageLoader open={isLoadingTenants}>
            <Container fluid>
                <Row className="mb-3 align-items-center">
                    <Col md={6}>
                        <Form.Group controlId="activeTenantsCheckbox" className="d-flex align-items-center">
                            <Form.Check
                                type="checkbox"
                                label={<span style={{ fontSize: '1.1rem', fontWeight: 'bold' }}>Show Active Tenants Only</span>}
                                checked={showActiveTenants}
                                onChange={(e) => handleShowActiveTenants(e.target.checked)}
                            />
                        </Form.Group>
                    </Col>
                </Row>
                <Row>
                    {tenants.map((tenant, index) => (
                        <Col md={4} key={index} style={{ marginBottom: '15px' }}>
                            <Card className="tenant-hover" onClick={() => handleCardClick(tenant, index)} style={{ cursor: 'pointer' }}>
                                <Card.Header className="d-flex justify-content-between align-items-center">
                                    <span>{tenant.domain}</span>
                                </Card.Header>
                                <Card.Body>
                                    <Card.Title>{tenant.name}</Card.Title>
                                    <Card.Subtitle className="mb-2 text-muted">{tenant.domain}</Card.Subtitle>
                                    <Card.Text>{tenant.description}</Card.Text>
                                    <Card.Text style={{ color: getLastActivityColor(tenant.last_activity) }}>Last activity: {format(new Date(tenant.last_activity), 'yyyy-MM-dd HH:mm:ss')}</Card.Text>
                                </Card.Body>
                            </Card>
                        </Col>
                    ))}
                    <Col md={4}>
                        <Button className="bg-blue-600" variant="primary" onClick={() => setShowCreate(true)}>
                            Add Tenant
                        </Button>
                    </Col>
                </Row>
                <ModalCreate show={showCreate} onHide={handleCreateTenant} />
            </Container>
        </PageLoader>
    )
}
