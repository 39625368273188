/*
 * Author: dizhong zhu
 * Date: 09/08/2024
 */
import React, { useRef, useState } from 'react'
import { CloseButton, Container, Form, Overlay, Popover, Row, OverlayTrigger, Tooltip } from 'react-bootstrap'
import Pagination from 'react-bootstrap/Pagination'
import DatePicker from 'react-datepicker'
import { format, startOfWeek, startOfMonth, endOfWeek, endOfMonth, subWeeks, subMonths } from 'date-fns'

enum DateRange {
    Today = 0,
    Yesterday = 1,
    Week = 2,
    Month = 3,
    Quarter = 4,
    HalfYear = 5,
    Year = 6,
    ThisWeek = 7,
    LastWeek = 8,
    ThisMonth = 9,
    LastMonth = 10,
    ShowAll = 11,
    Customise = 1000,
}

const paginationItems = [
    { range: DateRange.Today, label: 'Today' },
    { range: DateRange.Yesterday, label: 'Yesterday' },
    { range: DateRange.ThisWeek, label: 'This Week' },
    { range: DateRange.LastWeek, label: 'Last Week' },
    { range: DateRange.ThisMonth, label: 'This Month' },
    { range: DateRange.LastMonth, label: 'Last Month' },
    { range: DateRange.Week, label: '7D' },
    { range: DateRange.Month, label: '30D' },
    { range: DateRange.Quarter, label: '3M' },
    { range: DateRange.HalfYear, label: '6M' },
    { range: DateRange.Year, label: '12M' },
    { range: DateRange.ShowAll, label: 'Show All' },
    { range: DateRange.Customise, label: 'Custom' },
]

interface DividerProps {
    className: string
}

export const Divider = ({ className }: DividerProps) => {
    const dividerStyle = {
        borderTop: '1px solid rgba(0, 0, 0, 0.1)',
    }

    return <div className={`divider ${className}`} style={dividerStyle}></div>
}

interface TimeFiltersProps {
    startDate: Date | undefined
    OnStartDateChange: (date: any) => void
    endDate: Date | undefined
    OnEndDateChange: (date: any) => void
}

export function TimeFilters({ startDate, OnStartDateChange, endDate, OnEndDateChange }: TimeFiltersProps) {
    const [activePage, setActivePage] = useState(DateRange.ShowAll)
    const [showDatePicker, setShowDatePicker] = useState(false)
    const customDateTarget = useRef(null)

    const closeDatePicker = () => {
        setShowDatePicker(false)
    }

    const handleEndDatePickerChange = (date: Date | null) => {
        OnEndDateChange(date)
        setShowDatePicker(false)
    }

    const handlePageClick = (range: DateRange) => {
        if (range === DateRange.Customise) {
            setShowDatePicker(true)
        } else {
            const endDate = getEndDate(range)
            const startDate = getStartDate(range)

            OnStartDateChange(startDate)
            OnEndDateChange(endDate)
            setShowDatePicker(false)
        }

        setActivePage(range)
    }

    const getTooltipContent = (range: DateRange) => {
        const endDate = getEndDate(range)
        const startDate = getStartDate(range)
        switch (range) {
            case DateRange.Today:
            case DateRange.Yesterday:
                return `${format(startDate, 'dd/MMM/yyyy')} (${getTimeZoneString()})`
            case DateRange.Week:
            case DateRange.Month:
            case DateRange.Quarter:
            case DateRange.HalfYear:
            case DateRange.Year:
            case DateRange.ThisWeek:
            case DateRange.LastWeek:
            case DateRange.ThisMonth:
            case DateRange.LastMonth:
                return `${format(startDate, 'dd/MMM/yyyy')} - ${format(endDate, 'dd/MMM/yyyy')} (${getTimeZoneString()})`
            case DateRange.ShowAll:
                return 'Show All'
            default:
                return ''
        }
    }

    const getTimeZoneString = () => {
        const now = new Date()
        return `GMT${now.getTimezoneOffset() < 0 ? '+' : ''}${now.getTimezoneOffset() / -60}`
    }

    const getEndDate = (range: DateRange) => {
        const endDate = new Date()
        switch (range) {
            case DateRange.Today:
            case DateRange.Week:
            case DateRange.Month:
            case DateRange.Quarter:
            case DateRange.HalfYear:
            case DateRange.Year:
            case DateRange.ThisWeek:
            case DateRange.ThisMonth:
            case DateRange.ShowAll:
                break
            case DateRange.Yesterday:
                endDate.setHours(0, 0, 0, 0)
                break
            case DateRange.LastWeek:
                endDate.setHours(0, 0, 0, 0)
                return endOfWeek(subWeeks(endDate, 1), { weekStartsOn: 1 })
            case DateRange.LastMonth:
                endDate.setHours(0, 0, 0, 0)
                return endOfMonth(subMonths(endDate, 1))
            default:
                return endDate
        }

        return endDate
    }

    const getStartDate = (range: DateRange) => {
        const startDate = new Date()
        startDate.setHours(0, 0, 0, 0)
        switch (range) {
            case DateRange.Today:
                break
            case DateRange.Yesterday:
                startDate.setDate(startDate.getDate() - 1)
                break
            case DateRange.Week:
                startDate.setDate(startDate.getDate() - 7)
                break
            case DateRange.Month:
                startDate.setMonth(startDate.getMonth() - 1)
                break
            case DateRange.Quarter:
                startDate.setMonth(startDate.getMonth() - 3)
                break
            case DateRange.HalfYear:
                startDate.setMonth(startDate.getMonth() - 6)
                break
            case DateRange.Year:
                startDate.setFullYear(startDate.getFullYear() - 1)
                break
            case DateRange.ShowAll:
                return new Date(0)
            case DateRange.ThisWeek:
                return startOfWeek(startDate, { weekStartsOn: 1 })
            case DateRange.LastWeek:
                return startOfWeek(subWeeks(startDate, 1), { weekStartsOn: 1 })
            case DateRange.ThisMonth:
                return startOfMonth(startDate)
            case DateRange.LastMonth:
                return startOfMonth(subMonths(startDate, 1))
            default:
                return ''
        }

        return startDate
    }

    return (
        <Container fluid className="p-0">
            <Row>
                <Pagination>
                    {paginationItems.map((item) => (
                        <OverlayTrigger key={item.range} placement="top" overlay={<Tooltip style={{ position: 'fixed' }}>{getTooltipContent(item.range)}</Tooltip>}>
                            <Pagination.Item active={activePage === item.range} onClick={() => handlePageClick(item.range)} ref={item.range === DateRange.Customise ? customDateTarget : undefined}>
                                {item.label}
                            </Pagination.Item>
                        </OverlayTrigger>
                    ))}
                </Pagination>
                <Overlay target={customDateTarget.current} show={showDatePicker} placement="right">
                    <Popover>
                        <Popover.Header className="d-flex justify-content-between align-items-center">
                            <span>Select Date Range</span>
                            <CloseButton className="bg-blue-600" style={{ outline: 'none' }} onClick={closeDatePicker} />
                        </Popover.Header>
                        <Popover.Body>
                            <Form.Label>Start Time:</Form.Label>
                            <DatePicker selected={startDate} onChange={OnStartDateChange} selectsStart startDate={startDate} endDate={endDate} placeholderText="Start Date" />
                            <Form.Label>End Time:</Form.Label>
                            <DatePicker
                                selected={endDate}
                                onChange={handleEndDatePickerChange}
                                onCalendarClose={closeDatePicker}
                                selectsEnd
                                startDate={startDate}
                                endDate={endDate}
                                placeholderText="End Date"
                            />
                        </Popover.Body>
                    </Popover>
                </Overlay>
            </Row>
        </Container>
    )
}
