/*
 * Author: dizhong zhu
 * Date: 29/08/2024
 */

import React, { Component, createRef, RefObject } from 'react'
import { Grid, GridCellProps, GridColumn as Column, GridToolbar, GridDataStateChangeEvent } from '@progress/kendo-react-grid'
import { process, State, CompositeFilterDescriptor, filterBy, DataResult } from '@progress/kendo-data-query'
import { ExcelExport, ExcelExportColumn } from '@progress/kendo-react-excel-export'
import { Button, OverlayTrigger, Tooltip } from 'react-bootstrap'
import { DebouncedInput } from 'components/DebouncedInput'
import { TransactionT } from 'store/analytics'
import { filterIcon } from '@progress/kendo-svg-icons'
import { GridColumnMenuCheckboxFilter, GridColumnMenuProps } from '@progress/kendo-react-grid'
import { PopoverAceCell } from '../../widgets/GridCellPopoover'

export const ColumnMenuCheckboxFilter = (props: GridColumnMenuProps & { data: TransactionT[] }) => {
    return (
        <div>
            <GridColumnMenuCheckboxFilter
                {...props}
                data={props.data} // Use the passed transactionData here
                expanded
            />
        </div>
    )
}

interface props {
    transactionItems: TransactionT[]
}

interface state {
    transactionData: TransactionT[]
    dataState: State
    dataResult: DataResult
    tooltipVisible: boolean
    target: any
    currentCellRowIndex: number | null
    currentCellColumnField: string | undefined
}

export class TransactionTable extends Component<props, state> {
    // private _export = createRef<ExcelExport | null>();

    private readonly _export: RefObject<ExcelExport>
    private columns: any[] // Declare columns as a class property
    private hideTooltipTimeout: ReturnType<typeof setTimeout> | null = null
    private transactionOrigItems: TransactionT[]

    constructor(props: props) {
        super(props)

        this.transactionOrigItems = this._addRecommendSizeToData(props.transactionItems)

        const initialDataState: State = {
            take: 20,
            skip: 0,
            filter: {
                logic: 'and',
                filters: [],
            },
            sort: [{ field: 'created_at', dir: 'desc' }],
        }

        this.state = {
            transactionData: this.transactionOrigItems,
            dataState: initialDataState,
            dataResult: process(this.transactionOrigItems, initialDataState),
            tooltipVisible: false,
            target: null,
            currentCellRowIndex: null,
            currentCellColumnField: undefined,
        }

        this.columns = [
            { field: 'order_number', title: 'Order Number' },
            { field: 'order_id', title: 'Order ID' },
            { field: 'scan_id', title: 'Scan ID' },
            { field: 'quick_size_id', title: 'Quick Size ID' },
            { field: 'product_name', title: 'Product Name}' },
            { field: 'variant_name', title: 'Variant' },
            {
                field: 'recommend_size',
                title: 'Recommend Size',
                filterable: false,
                cell: (props: GridCellProps) => {
                    return (
                        <PopoverAceCell
                            {...props}
                            title="Recommend Sizes"
                            tooltipVisible={this.state.tooltipVisible}
                            target={this.state.target}
                            currentCellRowIndex={this.state.currentCellRowIndex}
                            currentCellColumnField={this.state.currentCellColumnField}
                            showTooltip={this.showTooltip}
                            hideTooltip={this.hideTooltip}
                        />
                    )
                },
            },
            { field: 'price', title: 'Price' },
            { field: 'quantity', title: 'Quantity' },
            { field: 'email', title: 'Customer Email' },
            {
                field: 'refund_amount',
                title: 'Refund',
                columnMenu: (props: GridColumnMenuProps) => <ColumnMenuCheckboxFilter {...props} data={this.state.transactionData} />,
            },
            {
                field: 'use_voucher',
                title: 'Use Voucher',
                filterable: false,
                columnMenu: (props: GridColumnMenuProps) => <ColumnMenuCheckboxFilter {...props} data={this.state.transactionData} />,
                cell: (props: GridCellProps) => {
                    const field = props.field || ''
                    return (
                        <td>
                            <input type="checkbox" checked={props.dataItem[field]} readOnly />
                        </td>
                    )
                },
            },
            {
                field: 'fulfill',
                title: 'Fulfilled',
                columnMenu: (props: GridColumnMenuProps) => <ColumnMenuCheckboxFilter {...props} data={this.state.transactionData} />,
                filterable: false,
                cell: (props: GridCellProps) => {
                    const field = props.field || ''
                    return (
                        <td>
                            <input type="checkbox" checked={props.dataItem[field]} readOnly />
                        </td>
                    )
                },
            },
            {
                field: 'cancel',
                title: 'Cancelled',
                filterable: false,
                columnMenu: (props: GridColumnMenuProps) => <ColumnMenuCheckboxFilter {...props} data={this.state.transactionData} />,
                cell: (props: GridCellProps) => {
                    const field = props.field || ''
                    return (
                        <td>
                            <input type="checkbox" checked={props.dataItem[field]} readOnly />
                        </td>
                    )
                },
            },
            {
                field: 'return',
                title: 'Return',
                filterable: false,
                columnMenu: (props: GridColumnMenuProps) => <ColumnMenuCheckboxFilter {...props} data={this.state.transactionData} />,
                cell: (props: GridCellProps) => {
                    const field = props.field || ''
                    return (
                        <td>
                            <input type="checkbox" checked={props.dataItem[field]} readOnly />
                        </td>
                    )
                },
            },
            {
                field: 'use_aistetic',
                title: 'Use Aistetic',
                columnMenu: (props: GridColumnMenuProps) => <ColumnMenuCheckboxFilter {...props} data={this.state.transactionData} />,
                filterable: false,
                cell: (props: GridCellProps) => {
                    const field = props.field || ''
                    return (
                        <td>
                            <input type="checkbox" checked={props.dataItem[field]} readOnly />
                        </td>
                    )
                },
            },
            {
                field: 'created_at',
                title: 'Time',
                filterable: false,
                cell: (props: GridCellProps) => {
                    const field = props.field as keyof TransactionT
                    const dateValue = props.dataItem[field]
                    const formattedDate = dateValue ? new Date(dateValue).toLocaleString() : ''
                    return <td>{formattedDate}</td>
                },
            },
            { field: 'return_reason', title: 'Return Reason' },
        ]

        this._export = createRef<ExcelExport>()
    }

    showTooltip = (target: any, rowIndex: number, columnField: string | undefined) => {
        if (this.hideTooltipTimeout) {
            clearTimeout(this.hideTooltipTimeout)
        }
        this.setState({
            tooltipVisible: true,
            target,
            currentCellRowIndex: rowIndex,
            currentCellColumnField: columnField,
        })
    }

    hideTooltip = () => {
        this.hideTooltipTimeout = setTimeout(() => {
            this.setState({
                tooltipVisible: false,
                currentCellRowIndex: null,
                currentCellColumnField: undefined,
            })
        }, 300)
    }

    componentDidUpdate(prevProps: props) {
        if (prevProps.transactionItems !== this.props.transactionItems) {
            this.transactionOrigItems = this._addRecommendSizeToData(this.props.transactionItems)
            this.setState({ transactionData: this.transactionOrigItems })
        }
    }

    excelExport = () => {
        if (this._export.current !== null) {
            this._export.current.save()
        }
    }

    handleDataStateChange = (event: GridDataStateChangeEvent) => {
        this.setState({ dataState: event.dataState })
        this.setState({ dataResult: process(this.state.transactionData, event.dataState) })
    }

    filterData = (value: any) => {
        const filter: CompositeFilterDescriptor = {
            logic: 'or',
            filters: this.columns
                .filter((column) => column.field && column?.filterable !== false)
                .map((column) => ({
                    field: column.field,
                    operator: 'contains',
                    value: value,
                })),
        }
        this.setState({ dataResult: process(filterBy(this.transactionOrigItems, filter), this.state.dataState) })
    }

    _addRecommendSizeToData(transactionData: TransactionT[]): TransactionT[] {
        return transactionData.map((item) => {
            const quickSizeDetail = item.QuickSizeDetail
            const scanDetail = item.ScanDetail

            let recommendSize = ''

            if (quickSizeDetail && quickSizeDetail.size) {
                recommendSize = quickSizeDetail.size
            } else if (scanDetail && scanDetail.sizes) {
                recommendSize = scanDetail.sizes
            }

            return {
                ...item,
                recommend_size: recommendSize, // Add the computed "Recommend Size" field to each item
            }
        })
    }

    render() {
        const { transactionData, dataState, dataResult } = this.state
        return (
            <>
                <ExcelExport fileName="transactions.xlsx" data={transactionData} ref={this._export}>
                    {this.columns.map((col, idx) => (
                        <ExcelExportColumn key={idx} field={col.field} title={col.title} />
                    ))}
                </ExcelExport>
                <Grid
                    className="nowrap-grid"
                    data={dataResult}
                    {...dataState}
                    selectable={{
                        enabled: true,
                        drag: false,
                        cell: false,
                        mode: 'multiple',
                    }}
                    pageable={{
                        type: 'input',
                        pageSizes: [5, 10, 15, 20, 30],
                        previousNext: true,
                    }}
                    columnMenuIcon={filterIcon}
                    resizable
                    skip={dataState.skip}
                    take={dataState.take}
                    total={dataResult.total}
                    sortable
                    sort={dataState.sort}
                    onDataStateChange={this.handleDataStateChange}
                >
                    <GridToolbar>
                        <DebouncedInput value={''} onChange={(value) => this.filterData(value)} className="p-2 font-lg shadow border border-block" placeholder="Search all columns..." />
                        <OverlayTrigger placement="bottom" delay={{ show: 250, hide: 200 }} overlay={<Tooltip style={{ position: 'fixed' }}>Export all data to Excel</Tooltip>}>
                            <Button className="bg-blue-600" onClick={this.excelExport}>
                                Export to Excel
                            </Button>
                        </OverlayTrigger>
                    </GridToolbar>
                    {this.columns.map((col, idx) => (
                        <Column key={idx} {...col} />
                    ))}
                </Grid>
            </>
        )
    }
}
