/*
 * Author: dizhong zhu
 * Date: 31/03/2023
 */

import React, { useEffect, useRef } from 'react'
import * as THREE from 'three'
import { OBJLoader } from 'three/examples/jsm/loaders/OBJLoader'
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls'

const ScanObj = ({ obj_b64 }) => {
    const containerRef = useRef()

    useEffect(() => {
        const scene = new THREE.Scene()
        const containerWidth = containerRef.current.clientWidth
        const containerHeight = containerRef.current.clientHeight
        const camera = new THREE.PerspectiveCamera(75, containerWidth / containerHeight, 0.1, 1000)

        const renderer = new THREE.WebGLRenderer({ antialias: true, alpha: true })
        renderer.setSize(containerWidth, containerHeight)
        containerRef.current.appendChild(renderer.domElement)

        // Add OrbitControls for zoom and rotation
        const controls = new OrbitControls(camera, renderer.domElement)
        controls.enableZoom = true
        controls.zoomSpeed = 1.0

        const loader = new OBJLoader()

        // Add an ambient light
        const ambientLight = new THREE.AmbientLight(0xffffff, 0.5)
        scene.add(ambientLight)

        // Add a point light
        const lightPositions = [new THREE.Vector3(10, 10, 10), new THREE.Vector3(-10, 10, 10), new THREE.Vector3(10, 10, -10), new THREE.Vector3(-10, 10, -10)]
        lightPositions.forEach((position) => {
            const pointLight = new THREE.PointLight(0xffffff, 0.1)
            pointLight.position.copy(position)
            scene.add(pointLight)
        })

        // Add a dynamic point light that follows the camera
        const cameraLight = new THREE.PointLight(0xffffff, 1)
        cameraLight.distance = 10
        // cameraLight.decay = 2;
        camera.add(cameraLight)
        scene.add(camera)

        if (obj_b64) {
            // Decode the base64 string back to binary data
            const objBinaryData = atob(obj_b64)

            // Convert binary data to an ArrayBuffer
            const arrayBuffer = new ArrayBuffer(objBinaryData.length)
            const byteArray = new Uint8Array(arrayBuffer)
            for (let i = 0; i < objBinaryData.length; i++) {
                byteArray[i] = objBinaryData.charCodeAt(i)
            }

            // Create a Blob from the ArrayBuffer
            const objBlob = new Blob([arrayBuffer], { type: 'model/obj' })
            const objURL = URL.createObjectURL(objBlob)

            loader.load(
                objURL,
                (object) => {
                    object.traverse(function (child) {
                        if (child instanceof THREE.Mesh) {
                            child.material = new THREE.MeshStandardMaterial({
                                color: 0x7f7f7f, // Change the color to your liking
                                side: THREE.DoubleSide,
                                metalness: 0.01,
                                roughness: 0.5,
                            })
                            child.geometry.computeBoundingSphere()
                            const center = child.geometry.boundingSphere.center
                            controls.target = center
                            // child.material.side = THREE.DoubleSide;
                        }
                    })

                    // Set the object scale, position, and rotation if needed
                    object.scale.set(2, 2, 2)
                    object.position.set(0, 0.5, 0)
                    object.rotation.set(0, 0, 0)
                    scene.add(object)
                    camera.position.z = 3
                    const animate = () => {
                        requestAnimationFrame(animate)
                        controls.update()
                        renderer.render(scene, camera)
                    }
                    animate()
                },
                (xhr) => {
                    console.log((xhr.loaded / xhr.total) * 100 + '% loaded')
                },
                (error) => {
                    console.error('An error happened', error)
                }
            )
        }

        return () => {
            renderer.dispose()
            if (containerRef.current) {
                containerRef.current.removeChild(renderer.domElement)
            }
        }
    }, [obj_b64])

    return <div ref={containerRef} style={{ width: '100%', height: '100%' }} />
}

export default ScanObj
