import SignUp from 'pages/Dashboard/SignUps/SignUp'
import React from 'react'
import { FORGOT_PASSWORD_ROUTE, LOGIN_ROUTE, REGISTER_ROUTE, RESET_PASSWORD_ROUTE } from 'routes/path'
import ForgotPassword from 'views/auth/ForgotPassword'
import { Login } from 'views/auth/Login'
import { ResetPassword } from '../pages/Dashboard/SignUps/ResetPassword'

const authRoutes = [
    {
        path: REGISTER_ROUTE,
        element: React.createElement(SignUp),
        restricted: false,
    },
    {
        path: LOGIN_ROUTE,
        element: React.createElement(Login),
        restricted: false,
    },
    {
        path: FORGOT_PASSWORD_ROUTE,
        element: React.createElement(ForgotPassword),
        restricted: false,
    },
    {
        path: RESET_PASSWORD_ROUTE,
        element: React.createElement(ResetPassword),
        restricted: false,
    },
]

export default authRoutes
